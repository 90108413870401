import numbro from 'numbro';

const WAN = 1e4;
const YI = 1e8;

export const formatter = {
  format: (n: number) => {
    return numbro(n).format({ thousandSeparated: true });
  },
};

export const numberToChinese = (str: string) => {
  str = str + '';
  const len = str.length - 1;
  const index = [
    '',
    '十',
    '百',
    '千',
    '萬',
    '十',
    '百',
    '千',
    '億',
    '十',
    '百',
    '千',
    '萬',
    '十',
    '百',
    '千',
    '億',
  ];
  const num = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];
  return str.replace(/([1-9]|0+)/g, function ($, $1, idx) {
    let pos = 0;
    if ($1[0] != '0') {
      pos = len - idx;
      if (idx == 0 && $1[0] == 1 && index[pos] == '十') {
        return index[pos];
      }
      return num[$1[0]] + index[pos];
    } else {
      let left = pos;
      let right = pos + $1.length;
      if (Math.floor(right / 4) - Math.floor(left / 4) > 0) {
        pos = left - (left % 4);
      }
      if (pos) {
        return index[pos] + num[$1[0]];
      } else if (idx + $1.length >= len) {
        return '';
      } else {
        return num[$1[0]];
      }
    }
  });
};

export const chineseNumeralFormatter = (
  n: number,
  shouldRound?: boolean
): string | number => {
  if (isNaN(n)) return 0;

  if (n < YI) {
    return `$${formatter.format(
      shouldRound ? Math.round(n / WAN) : n / WAN
    )} 萬`;
  } else {
    return `$${formatter.format(shouldRound ? Math.round(n / YI) : n / YI)} 億`;
  }
};
