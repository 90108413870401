import html2canvas from 'html2canvas';

export const nodeToCanvas = async (node: HTMLElement): Promise<Blob> => {
  const canvas = await html2canvas(node, { useCORS: true });

  return await canvasToBlob(canvas);
};

export const canvasToBlob = (canvas: HTMLCanvasElement) =>
  new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      return blob
        ? resolve(blob as Blob)
        : reject(new Error('failed to convert clone to canvas'));
    });
  });
