/**
 * @param {HTMLImageElement} image - Image File Object
 * @param {Object} crop - crop Object
 * @param {String} fileName - Name of the returned file in Promise
 * @see https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/drawImage
 */
import ReactCrop from 'react-image-crop';
import Compressorjs from 'compressorjs';

export async function getCroppedImage(
  image: HTMLImageElement,
  originalImage: HTMLImageElement,
  crop: ReactCrop.Crop,
  fileName: string
) {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const longer =
    image.naturalWidth > image.naturalHeight ? 'naturalWidth' : 'naturalHeight';
  const scale = (originalImage?.[longer] || image?.[longer]) / 1440;
  canvas.width = (originalImage?.naturalWidth || image.naturalWidth) / scale;
  canvas.height = (originalImage?.naturalHeight || image.naturalHeight) / scale;
  const ctx = canvas.getContext('2d')!;

  ctx.drawImage(
    image,
    (crop.x || 0) * scaleX,
    (crop.y || 0) * scaleY,
    (crop.width || 0) * scaleX,
    (crop.height || 0) * scaleY,
    0,
    0,
    (originalImage?.naturalWidth || image.naturalWidth) / scale,
    (originalImage?.naturalHeight || image.naturalHeight) / scale
  );
  // As a blob
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          return reject(new Error('generate cropped image failed'));
        }
        // @ts-ignore
        blob.name = fileName;
        resolve(blob);
      },
      'image/png',
      1
    );
  });
}

const loadImage = (src: string) =>
  new Promise<HTMLImageElement>((resolve) => {
    const image = document.createElement('img');

    image.style.position = 'absolute';
    image.style.top = '-999999px';
    image.style.left = '-999999px';
    image.style.maxWidth = 'fit-content';
    image.src = src;
    image.onload = function () {
      resolve(image);
    };
    document.body.appendChild(image);
  });

export const getSelfCropImage = async (
  imageRef: HTMLImageElement,
  crop: ReactCrop.Crop,
  fileName: string
) => {
  const canvas = document.createElement('canvas');
  const image = await loadImage(imageRef.src);

  const { x = 0, y = 0, height: dy = 0, width: dx = 0 } = crop;

  const width = image.width;
  const height = image.height;
  const widthRef = imageRef.width;
  const heightRef = imageRef.height;

  const xRatio = x / widthRef;
  const yRatio = y / heightRef;
  const dxRatio = (x + dx) / widthRef;
  const dyRatio = (y + dy) / heightRef;

  const sx = xRatio * width;
  const sy = yRatio * height;
  const sWidth = dxRatio * width;
  const sHeight = dyRatio * height;

  const ctx = canvas.getContext('2d')!;
  canvas.width = sWidth - sx;
  canvas.height = sHeight - sy;
  ctx.drawImage(image, sx, sy, sWidth, sHeight, 0, 0, sWidth, sHeight);

  image.remove();
  // As a blob
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      async (blob) => {
        if (!blob) {
          return reject(new Error('generate cropped image failed'));
        }
        const file = await compressor({
          width: sWidth,
          height: sHeight,
          file: blob,
        });
        // @ts-ignore
        blob.name = fileName;
        resolve(file);
      },
      'image/jpeg',
      1
    );
  });
};

const compressor = ({
  width,
  height,
  file,
}: {
  file: Blob | File;
  width: number;
  height: number;
}) =>
  new Promise<File | Blob>((resolve, reject) => {
    const dimensionConfig: Compressorjs.Options =
      width > height
        ? {
            maxWidth: 1920,
            maxHeight: (1920 / 3) * 2,
          }
        : {
            maxWidth: (1920 / 3) * 2,
            maxHeight: 1920,
          };
    new Compressorjs(file, {
      ...dimensionConfig,
      quality: 1,
      success: (result) => resolve(result),
      error: (err) => reject(err),
    });
  });

export const cropImageByAspect = (
  originalImage: HTMLImageElement,
  image: HTMLImageElement,
  crop: ReactCrop.Crop,
  fileName: string
) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  const width = (crop.width ?? 0) * scaleX;
  const height = (crop.height ?? 0) * scaleY;
  const x = (crop.x ?? 0) * scaleX;
  const y = (crop.y ?? 0) * scaleY;
  // const aspect = (crop.aspect ?? 0) * scale;
  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d')!;

  ctx.drawImage(originalImage, x, y, width, height, 0, 0, width, height);

  // As a blob
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob(
      async (blob) => {
        if (!blob) {
          return reject(new Error('generate cropped image failed'));
        }

        // @ts-ignore
        blob.name = fileName;
        resolve(blob);
      },
      'image/png',
      1
    );
  });
};
