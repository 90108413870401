// https://developers.facebook.com/docs/marketing-api/error-reference/
export function errorHandler(errorCode: number) {
  let code = 'UNKNOWN';
  switch (errorCode) {
    case 4:
      code = 'REQUEST_LIMIT';
      break;
    case 100:
      code = 'LACK_COLUMN';
      break;
    case 102:
    case 104:
    case 190:
    case 200:
      code = 'PERMISSION_NOT_ALLOW';
      break;
  }

  return code;
}
