import React, { useEffect, useState } from 'react';

import Link from 'next/link';
import { useRouter } from 'next/router';

interface Props {
  children: React.FC<{ className: string; isActive: boolean }>;
  activeClassName?: string;
  href: string;
  as?: string;
  className?: string;
  foreign?: boolean;
}

const ActiveLink: React.FC<Props> = ({
  children,
  className = '',
  activeClassName,
  foreign,
  ...props
}) => {
  const { asPath, isReady } = useRouter();

  const [assembledClassName, setAssembledClassName] =
    useState<string>(className);
  const [isActive, setIsActive] = useState<boolean>(false);

  useEffect(() => {
    if (foreign) return setIsActive(false);
    // Check if the router fields are updated client-side
    if (isReady) {
      // Dynamic route will be matched via props.as
      // Static route will be matched via props.href
      const linkPathname = new URL(props.as || props.href, location.href)
        .pathname;
      // Using URL().pathname to get rid of query and hash
      const activePathname = new URL(asPath, location.href).pathname;

      setIsActive(
        activePathname === linkPathname ||
          activePathname.startsWith(`${linkPathname}/`)
      );
    }
  }, [asPath, isReady, props.as, props.href]);

  useEffect(() => {
    const newClassName = isActive
      ? `${className} ${activeClassName}`.trim()
      : className;

    if (newClassName !== assembledClassName) {
      setAssembledClassName(newClassName);
    }
  }, [
    setAssembledClassName,
    className,
    activeClassName,
    assembledClassName,
    isActive,
  ]);

  return (
    <Link {...props} passHref>
      {children({ isActive, className: assembledClassName })}
    </Link>
  );
};

export default ActiveLink;
