import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import jwt_decode from 'jwt-decode';

import { FUNWOO_TOKEN_KEY } from '@src/constant';

const useJWT = () => {
  const [data, setData] = useState<UserInfoProps | null>(null);

  useEffect(() => {
    try {
      const jwt = Cookies.get(FUNWOO_TOKEN_KEY) as string;
      setData(jwt_decode(jwt));
    } catch (error) {}
  }, []);
  return data;
};

export { useJWT };
