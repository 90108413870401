import { isBrowser } from 'react-use/lib/misc/util';

import axios from 'axios';

import { onRequestFulFilled, onResponseRejected } from '@src/fetcher';

import { Api as FunwooAPIInstance } from './funwoo.api';

const instance = axios.create({
  baseURL: isBrowser
    ? `${window.location.origin}/rewrite/funwoo-api`
    : process.env.NEXT_PUBLIC_FUNWOO_APIS!,
});

const instanceWithoutRewrite = axios.create({
  baseURL: process.env.NEXT_PUBLIC_FUNWOO_APIS!,
});

instance.interceptors.request.use(onRequestFulFilled);
instance.interceptors.response.use(void 0, onResponseRejected);

instanceWithoutRewrite.interceptors.request.use(onRequestFulFilled);
instanceWithoutRewrite.interceptors.response.use(void 0, onResponseRejected);

const FunwooAPI = new FunwooAPIInstance();
FunwooAPI.instance = instance;

const FunwooAPIWithNoRewrite = new FunwooAPIInstance();
FunwooAPIWithNoRewrite.instance = instanceWithoutRewrite;

export { FunwooAPI, FunwooAPIWithNoRewrite };
