/**
 * FUNWOO theme for backyard system
 * This defines color palette / font / font size and spacings to achieve consistency
 *
 * @see https://www.figma.com/file/8G4BhdALmCGPNQm79jsia8/Style-Guide?node-id=121%3A16
 * @see https://chakra-ui.com/docs/theming/theme
 */
import { ReactNode } from 'react';

import {
  ChakraProvider,
  extendTheme,
  ThemeConfig,
  withDefaultColorScheme,
} from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';
import { StyleFunctionProps } from '@chakra-ui/theme-tools/src/component';
import { Dict } from '@chakra-ui/utils';

const breakpoints = createBreakpoints({
  sm: '768px',
  md: '1024px',
  lg: '1440px',
  xl: '1920px',
  // see https://chakra-ui.com/docs/features/responsive-styles
  '2xl': '2560px',
});

const colors = {
  gray: {
    50: '#fafafa',
    100: '#f5f5f5',
    300: '#e0e0e0',
    500: '#9e9e9e',
    700: '#616161',
    800: '#424242',
    900: '#212121',
  },
  brand: '#ef7c41',
  gold: '#b49961',
};

const fonts = {
  mono: 'Roboto, monospace',
};

const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '3rem',
  '6xl': '4rem',
};

const config: ThemeConfig = {
  cssVarPrefix: 'fw',
};

const theme = extendTheme(
  {
    breakpoints,
    components: {
      Button: {
        baseStyle: {
          _focus: {
            boxShadow: 'none',
            outline: 'none',
          },
        },
        variants: {
          primary: (props: Dict | StyleFunctionProps) => ({
            bg: !props.disabled ? mode('black', 'white')(props) : 'gray.700',
            color: mode('white', 'black')(props),
            fontWeight: 400,
            borderRadius: 0,
            px: 8,
            py: 1,
            fontSize: '14px',
            lineHeight: '1.5rem',
            _hover: {
              background: 'black !important',
            },
          }),
          secondary: (props: Dict | StyleFunctionProps) => ({
            bg: mode('white', 'black')(props),
            color: mode('black', 'white')(props),
            fontWeight: 400,
            border: '1px solid',
            borderRadius: 0,
            px: 8,
            py: 1,
            fontSize: '14px',
            lineHeight: '1.5rem',
          }),
          alert: (props: Dict | StyleFunctionProps) => ({
            bg: mode('red.500', 'red.500')(props),
            color: mode('white', 'white')(props),
            fontWeight: 400,
            borderRadius: 0,
            px: 8,
            py: 1,
            fontSize: '14px',
            lineHeight: '1.5rem',
          }),
          green: (props: Dict | StyleFunctionProps) => ({
            bg: mode('green.500', 'green.500')(props),
            color: mode('white', 'white')(props),
            fontWeight: 400,
            borderRadius: 0,
            px: 8,
            py: 1,
            fontSize: '14px',
            lineHeight: '1.5rem',
          }),
        },
        defaultProps: {
          variant: 'primary',
          type: 'button',
        },
      },
    },
    colors,
    config,
    fonts,
    fontSizes,
  },
  withDefaultColorScheme({ colorScheme: 'brand' }),
  {
    components: {
      Checkbox: {
        defaultProps: {
          colorScheme: 'blue',
        },
      },
      Radio: {
        defaultProps: {
          colorScheme: 'blue',
        },
      },
    },
  }
);

export default function Themed({ children }: { children: ReactNode }) {
  return <ChakraProvider theme={theme}>{children}</ChakraProvider>;
}
