import { cloneDeep } from 'lodash';
import { ListingDetail, StateOfListing } from '@src/swagger/funwoo.api';
import { startOfDay } from 'date-fns';

export const cloneListingEditedData = (data: ListingDetail) => {
  const cloned = cloneDeep(data) as Partial<ListingDetail>;
  if (cloned.listing_state) {
    cloned.listing_state = cloned.listing_state.map((item, index) => ({
      ...item,
      expire_at: item.expire_at
        ? startOfDay(new Date(item.expire_at)).toISOString()
        : null,
    }));
    if (
      !cloned.listing_state.some((state) => state.state === StateOfListing.SOLD)
    ) {
      cloned.dealPrice = 0;
      cloned.sold_at = null;
    }
  }
  if (cloned.sold_at) {
    cloned.sold_at = startOfDay(new Date(cloned.sold_at)).toISOString();
  }
  return cloned;
};
