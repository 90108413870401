import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';

import axios from 'axios';

import { getAgeYear } from '@src/utils';

import {
  BuildingDescriptionModel,
  BuildingOtherRightModel,
  CMAPriceSearchModel,
  CMASearchResponseProps,
  OwnerDescriptionModel,
} from '@src/context';

type UserType = 'seller' | 'buyer';

interface CMATableContextProps {
  ageYear: number;
  onSalesSearchResult: Array<CMASearchResponseProps> | undefined;
  priceTableRows: Map<string, boolean>;
  onSaleTableRows: Map<string, boolean>;
  setOnSaleTableRows: (value: Map<string, boolean>) => void;
  displayTable: 'price' | 'onSale';
  openCustomRows: boolean;
  setOpenCustomRows: (value: boolean) => void;
  setDisplayTable: (value: 'price' | 'onSale') => void;
  priceSearchResult: CMAPriceSearchModel | undefined;
  userType: UserType;
  priceSearchCheckedItems: Map<string, boolean>;
  setPriceSearchCheckedItems: (value: Map<string, boolean>) => void;
  onSalesSearchCheckedItems: Map<string, boolean>;
  setOnSalesSearchCheckedItems: (value: Map<string, boolean>) => void;
  setPriceSearchResult: (value: CMAPriceSearchModel | undefined) => void;
  setPriceTableRows: (value: Map<string, boolean>) => void;
  setUserType: (value: UserType) => void;
  requestCMAResult: (data: any, callBack: () => void) => void;
  minPrice: string | undefined;
  setMinPrice: Dispatch<SetStateAction<undefined | string>>;
  maxPrice: string | undefined;
  setMaxPrice: Dispatch<SetStateAction<undefined | string>>;
  totalPing: number | undefined;
  mainBuildingAreaPing: number | undefined;
  loading: boolean;
  address: string;
  buildingData: IBuildData | null;
}

export const CMATableContext = React.createContext({} as CMATableContextProps);

export interface IBuildData {
  BuildingDescription: BuildingDescriptionModel;
  BuildingOtherRight: BuildingOtherRightModel;
  ownerData: OwnerDescriptionModel;
  totalPing: number;
  completeDate: string;
  buildType?: string;
  mainPing: number;
}

interface CMATableContextProviderProps {
  buildingData: IBuildData | null;
  address: string;
}

export const CMATableContextProvider: CommonComponent<
  CMATableContextProviderProps
> = ({ children, buildingData, address }) => {
  const [displayTable, setDisplayTable] = useState<'price' | 'onSale'>('price');
  const [onSaleTableRows, setOnSaleTableRows] = React.useState<
    Map<string, boolean>
  >(new Map());
  const [priceTableRows, setPriceTableRows] = React.useState<
    Map<string, boolean>
  >(new Map());
  const [openCustomRows, setOpenCustomRows] = React.useState<boolean>(false);
  const [onSalesSearchResult, setOnSalesSearchResult] =
    useState<Array<CMASearchResponseProps>>();
  const [priceSearchResult, setPriceSearchResult] =
    useState<CMAPriceSearchModel>();
  const [userType, setUserType] = useState<'seller' | 'buyer'>('seller');
  const [minPrice, setMinPrice] = useState<string | undefined>();
  const [maxPrice, setMaxPrice] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [priceSearchCheckedItems, setPriceSearchCheckedItems] = useState(
    new Map()
  );
  const [onSalesSearchCheckedItems, setOnSalesSearchCheckedItems] = useState(
    new Map()
  );
  const requestCMAResult = async (requestData: any, callback: any) => {
    try {
      setLoading(true);
      setPriceSearchCheckedItems(new Map());
      setOnSalesSearchCheckedItems(new Map());

      const { data: priceSearchResult } = await axios.post<CMAPriceSearchModel>(
        '/cma/api/price/search_houses',
        requestData
      );
      const { data: onSaleResult } = await axios.post<{
        results: Array<CMASearchResponseProps>;
      }>('/cma/api/price/search_currdb', requestData);

      let priceSearchDefaultRow = new Map();
      [
        'address',
        'transaction_date',
        'total_ping',
        'price',
        'unit_price',
        'age_yr',
        'space',
        'garage_num',
        'balcony_area_ping',
        'building_management',
        'building_type',
        'city',
        'district',
        'elevator',
        'floor',
        'garage_area',
        'garage_type',
        'land_ping',
        'location',
        'main_building_area_ping',

        'public_ping_ratio',
        'total_floors',
      ].map((item) => {
        if (
          item === 'address' ||
          item === 'transaction_date' ||
          item === 'price' ||
          item === 'total_ping' ||
          item == 'age_yr' ||
          item == 'space' ||
          item == 'garage_num' ||
          item == 'unit_price'
        ) {
          priceSearchDefaultRow.set(item, true);
        } else {
          priceSearchDefaultRow.set(item, false);
        }
      });
      let onSaleDefaultRow = new Map();
      [
        'raw_image_urls',
        'address',
        'space',
        'balcony_area_ping',
        'building_project',
        'city',
        'district',
        'floor',
        'main_building_area_ping',
        'total_floors',
        'total_ping',
        'age_yr',
      ].map((item) => {
        if (
          item === 'address' ||
          item === 'total_ping' ||
          item == 'age_yr' ||
          item == 'space' ||
          item == 'raw_image_urls'
        ) {
          onSaleDefaultRow.set(item, true);
        } else {
          onSaleDefaultRow.set(item, false);
        }
      });
      setOnSalesSearchResult(onSaleResult.results);
      setOnSaleTableRows(onSaleDefaultRow);
      setPriceTableRows(priceSearchDefaultRow);
      setPriceSearchResult(priceSearchResult);
      setLoading(false);
      if (callback) {
        callback();
      }
    } catch (error) {}
  };

  useEffect(() => {
    (async () => {
      // const { data } = await axios.get<string[]>('/cma/api/getListingRows');
      const temp = new Map();
      // data.map((item) => temp.set(item, false));
      setOnSaleTableRows(temp);
    })();
  }, []);
  return (
    <CMATableContext.Provider
      value={{
        setDisplayTable: setDisplayTable,
        onSalesSearchResult: onSalesSearchResult,
        setOpenCustomRows: setOpenCustomRows,
        openCustomRows: openCustomRows,
        displayTable,
        totalPing: buildingData?.totalPing,
        mainBuildingAreaPing: buildingData?.mainPing,
        setOnSaleTableRows: setOnSaleTableRows,
        onSaleTableRows: onSaleTableRows,
        priceTableRows: priceTableRows,
        setPriceSearchResult: setPriceSearchResult,
        priceSearchResult: priceSearchResult,
        setPriceTableRows: setPriceTableRows,
        userType: userType,
        setUserType: setUserType,
        requestCMAResult: requestCMAResult,
        minPrice: minPrice,
        setMinPrice: setMinPrice,
        maxPrice: maxPrice,
        ageYear: getAgeYear(buildingData?.completeDate),
        buildingData: buildingData,
        setMaxPrice: setMaxPrice,
        setPriceSearchCheckedItems: setPriceSearchCheckedItems,
        priceSearchCheckedItems: priceSearchCheckedItems,
        onSalesSearchCheckedItems: onSalesSearchCheckedItems,
        setOnSalesSearchCheckedItems: setOnSalesSearchCheckedItems,
        address: address,
        loading: loading,
      }}
    >
      {children}
    </CMATableContext.Provider>
  );
};

export const useCMATableContext = () => {
  return useContext(CMATableContext);
};
