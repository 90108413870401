import { NextApiResponse } from 'next';

import { isBrowser } from '@chakra-ui/utils';
import { redirect } from 'next/dist/server/api-utils';

import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import cookie from 'cookie';
import jsCookie from 'js-cookie';

import { FUNWOO_TOKEN_KEY } from '@/src/constant';
import { toast } from 'react-toastify';

const baseURL = isBrowser
  ? `${window.location.origin}/rewrite/backyard-api`
  : process.env.BACKYARD_API_URI;

export const Axios = axios.create({ baseURL });
export const onRequestFulFilled = (config: AxiosRequestConfig) => {
  if (typeof window !== 'undefined') {
    const cookies = cookie.parse(document.cookie);
    config.headers![FUNWOO_TOKEN_KEY] = cookies[FUNWOO_TOKEN_KEY];
  } else {
    const req = config.serverRequest;
    const cookie = req?.headers.cookie;
    if (cookie) {
      config.headers.cookie = cookie;
    }
  }

  return config;
};
export const onResponseRejected = (error: AxiosError) => {
  if (error.response?.status === 401) {
    if (typeof window !== 'undefined') {
      jsCookie.remove(FUNWOO_TOKEN_KEY);
      const token = jsCookie.get(FUNWOO_TOKEN_KEY);
      console.info(`token had expired`);
      console.info('token has been removed:::', !!token);
      console.info(`Then redirect to sign-in page`);
      window.location.href = '/auth/signin';
    } else {
      if (error.config.serverResponse) {
        redirect(
          error.config.serverResponse as NextApiResponse,
          307,
          '/auth/signin'
        );
      }
    }
  }

  if (error.response?.data) {
    toast.error(JSON.stringify(error.response.data));
  }

  throw error;
};

Axios.interceptors.request.use(onRequestFulFilled);
Axios.interceptors.response.use(void 0, onResponseRejected);
