import * as Sentry from '@sentry/nextjs';

const debug = process.env.RUNTIME_ENV === 'development';

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    enabled: !debug,
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    tracesSampleRate: 1.0,
  });
}

export function log(message: any) {
  if (debug) {
  } else {
    Sentry.captureMessage(message);
  }
}

export function error(ex: any) {
  if (debug) {
    console.error(ex);
  } else {
    Sentry.captureException(ex);
  }
}

export default {
  log,
  info: log,
  error,
};
