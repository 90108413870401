import React from 'react';

import { Icon } from '@chakra-ui/react';

interface IconComponent<Props = {}> {
  (props: React.SVGProps<any> & Props): React.ReactElement;
}

// 直接從 figma 複製 svg 過來
export const ChevronLeft: IconComponent = (props) => {
  const { stroke = 'white' } = props;
  return (
    <Icon viewBox='0 0 24 24' w='24px' h='24px'>
      <circle cx='12' cy='12' r='11.5' stroke={stroke} fill='none' />
      <path fill='none' d='M14 7L9 12L14 17' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
    </Icon>
  );
};

export const ChevronRight: IconComponent = (props) => {
  const { stroke = 'white' } = props;
  return (
    <Icon viewBox='0 0 24 24' w='24px' h='24px'>
      <circle cx='12' cy='12' r='11.5' stroke={stroke} fill='none' />
      <path fill='none' d='M9 17L14 12L9 7' stroke={stroke} strokeLinecap='round' strokeLinejoin='round' />
    </Icon>
  );
};

export const ChevronRightBig = () => (
  <Icon viewBox='0 0 12 21' w='12px' h='21px'>
    <path d='M11.67 2.27L9.9 0.5L0 10.4L9.9 20.3L11.67 18.53L3.54 10.4L11.67 2.27Z' fill='#BDBDBD' />
  </Icon>
);

// noinspection JSUnusedGlobalSymbols
export const HomeIcon = () => (
  <Icon viewBox='0 0 24 24' w='24px' h='24px'>
    <path
      fill='none'
      /* eslint-disable-next-line max-len */
      d='M3 14.5L4.5 13.5M11.5 8.95055L4.5 13.5M11.5 8.95055V4.49838M11.5 8.95055V19.5M11.5 19.5H4.5V13.5M11.5 19.5L14 19.5M11.5 4.49838L10.5 4L19.5 8.5M11.5 4.49838L19.5 8.5M20.5 8.95055L19.5 8.5M19.5 8.5V19.5H17M14 19.5V15.5H17V19.5M14 19.5L17 19.5'
      stroke='white'
      strokeOpacity='0.65'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <circle cx='15.5' cy='11.5' r='2' fill='white' fillOpacity='0.65' />
  </Icon>
);

export const PresentationIcon: IconComponent<{ selected: boolean }> = (props) => {
  const { selected } = props;
  const strokeOpacity = selected ? 1 : 0.65;
  return (
    <Icon viewBox='0 0 18 22' w='24px' h='24px'>
      <path
        fill='none'
        // eslint-disable-next-line
        d='M12.5669 1H4.78972C4.67926 1 4.58972 1.08955 4.58972 1.2V17.2706C4.58972 17.381 4.67926 17.4706 4.78972 17.4706H16.3555C16.466 17.4706 16.5555 17.381 16.5555 17.2706V5.39216M12.5669 1L16.5555 5.39216M12.5669 1V5.19216C12.5669 5.30261 12.6565 5.39216 12.7669 5.39216H16.5555'
        stroke='white'
        strokeOpacity={strokeOpacity}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        fill='none'
        d='M1 4.52942V21H12.9658'
        stroke='white'
        strokeOpacity={strokeOpacity}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </Icon>
  );
};

// noinspection JSUnusedGlobalSymbols
export const FlyerIcon: IconComponent<{ selected: boolean }> = (props) => {
  const { selected } = props;
  const strokeOpacity = selected ? 1 : 0.65;
  return (
    <Icon viewBox='0 0 24 24' w='24px' h='24px'>
      <path
        // eslint-disable-next-line
        d='M19.8 1H4.2C4.08954 1 4 1.08954 4 1.2V22.8C4 22.9105 4.08954 23 4.2 23H19.8C19.9105 23 20 22.9105 20 22.8V1.2C20 1.08954 19.9105 1 19.8 1Z'
        stroke='white'
        strokeOpacity={1}
        strokeLinecap='round'
        strokeLinejoin='round'
        fill='none'
      />
      <path d='M7 7H17' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 12H17' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 18H17' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' strokeLinejoin='round' />
    </Icon>
  );
};

//
export const SocialMediaIcon: IconComponent<{ selected: boolean }> = (props) => {
  const { selected } = props;
  const strokeOpacity = selected ? 1 : 0.65;
  return (
    <Icon viewBox='0 0 24 24' w='24px' h='24px'>
      <path
        // eslint-disable-next-line
        d='M4 15.8V5.2C4 5.08954 4.08954 5 4.2 5H19.8C19.9105 5 20 5.08954 20 5.2V15.8C20 15.9105 19.9105 16 19.8 16H17.0828C17.0298 16 16.9789 16.0211 16.9414 16.0586L14.3414 18.6586C14.2154 18.7846 14 18.6953 14 18.5172V16.2C14 16.0895 13.9105 16 13.8 16H4.2C4.08954 16 4 15.9105 4 15.8Z'
        stroke='white'
        strokeOpacity={strokeOpacity}
      />
      <path
        // eslint-disable-next-line
        d='M9.14025 8.83986C9.41948 8.19003 9.94765 7.95211 10.5701 8.00783C11.1181 8.05689 11.5959 8.44064 11.8358 8.66948C11.9254 8.755 12.0745 8.755 12.1642 8.66948C12.4041 8.44064 12.8819 8.05689 13.4298 8.00783C14.0523 7.95211 14.5805 8.19004 14.8597 8.83986C15.2173 9.67189 14.8598 10.5039 14.1448 11.3359C13.546 12.0328 12.4369 12.7348 12.0998 12.9402C12.0382 12.9777 11.9618 12.9777 11.9002 12.9402C11.563 12.7348 10.4539 12.0328 9.85518 11.3359C9.14031 10.5039 8.78273 9.67189 9.14025 8.83986Z'
        stroke='white'
        strokeOpacity={strokeOpacity}
      />
    </Icon>
  );
};

export const AdsIcon: IconComponent<{ selected: boolean }> = (props) => {
  const { selected } = props;
  const strokeOpacity = selected ? 1 : 0.65;
  return (
    <Icon viewBox='0 0 24 24' w='24px' h='24px'>
      <circle cx='12' cy='12' r='1.5' stroke='white' strokeOpacity={strokeOpacity} />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        // eslint-disable-next-line
        d='M10.5 3.12445C6.73126 3.75673 3.75673 6.73126 3.12445 10.5H4.14041C4.75035 7.2846 7.2846 4.75035 10.5 4.14041V3.12445ZM13.5 4.14041C16.7154 4.75035 19.2497 7.2846 19.8596 10.5H20.8756C20.2433 6.73126 17.2688 3.75673 13.5 3.12445V4.14041ZM19.8596 13.5C19.2509 16.7091 16.7254 19.2397 13.5191 19.856V20.8724C17.2788 20.2333 20.2444 17.2624 20.8756 13.5H19.8596ZM10.5191 19.8632C7.29461 19.2597 4.75155 16.7218 4.14041 13.5H3.12445C3.75779 17.2751 6.74128 20.2533 10.5191 20.8787V19.8632Z'
        fill='white'
        fillOpacity={strokeOpacity}
      />
      <path d='M12 2V5' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' />
      <path d='M12.019 19.5L12 21.9999' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' />
      <path d='M2 12H5' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' />
      <path d='M19 12H22' stroke='white' strokeOpacity={strokeOpacity} strokeLinecap='round' strokeLinejoin='round' />
    </Icon>
  );
};

// noinspection JSUnusedGlobalSymbols
export const VideoIcon: IconComponent<{ selected: boolean }> = (props) => {
  const { selected } = props;
  const strokeOpacity = selected ? 1 : 0.65;
  return (
    <Icon viewBox='0 0 24 24' w='24px' h='24px'>
      <path
        // eslint-disable-next-line
        d='M2 18.8V5.2C2 5.08954 2.08954 5 2.2 5H21.8C21.9105 5 22 5.08954 22 5.2V18.8C22 18.9105 21.9105 19 21.8 19H2.2C2.08954 19 2 18.9105 2 18.8Z'
        stroke='white'
        strokeOpacity={strokeOpacity}
      />
      <path
        // eslint-disable-next-line
        d='M15.7 11.8268C15.8333 11.9038 15.8333 12.0962 15.7 12.1732L10.3 15.2909C10.1667 15.3679 10 15.2717 10 15.1177V8.88231C10 8.72835 10.1667 8.63212 10.3 8.7091L15.7 11.8268Z'
        fill='white'
        fillOpacity={strokeOpacity}
      />
    </Icon>
  );
};

// noinspection JSUnusedGlobalSymbols
export const PrinterIcon = () => (
  <Icon viewBox='0 0 24 24' w='24px' h='24px'>
    <path
      // eslint-disable-next-line
      d='M6.5 9H2.2C2.08954 9 2 9.08954 2 9.2V17.8C2 17.9105 2.08954 18 2.2 18H7M17.5 9H21.8C21.9105 9 22 9.08954 22 9.2V17.8C22 17.9105 21.9105 18 21.8 18H17M7 18V21.8C7 21.9105 7.08954 22 7.2 22H16.8C16.9105 22 17 21.9105 17 21.8V18M7 18H17'
      stroke='white'
      strokeOpacity='0.65'
    />
    <path
      // eslint-disable-next-line
      d='M5 11.5C4.72386 11.5 4.5 11.7239 4.5 12C4.5 12.2761 4.72386 12.5 5 12.5V11.5ZM19 12.5C19.2761 12.5 19.5 12.2761 19.5 12C19.5 11.7239 19.2761 11.5 19 11.5V12.5ZM17.5 12V3.2H16.5V12H17.5ZM16.8 2.5H7.2V3.5H16.8V2.5ZM6.5 3.2V12H7.5V3.2H6.5ZM7 11.5H5V12.5H7V11.5ZM12 12.5H17V11.5H12V12.5ZM17 12.5H19V11.5H17V12.5ZM7 12.5H9.5V11.5H7V12.5ZM9.5 12.5H12V11.5H9.5V12.5ZM17.5 3.2C17.5 2.8134 17.1866 2.5 16.8 2.5V3.5C16.6343 3.5 16.5 3.36569 16.5 3.2H17.5ZM7.2 2.5C6.8134 2.5 6.5 2.8134 6.5 3.2H7.5C7.5 3.36568 7.36569 3.5 7.2 3.5V2.5Z'
      fill='white'
      fillOpacity='0.65'
    />
    <circle cx='16.5' cy='14.5' r='0.5' fill='white' fillOpacity='0.65' />
    <circle cx='18.5' cy='14.5' r='0.5' fill='white' fillOpacity='0.65' />
  </Icon>
);

export const Logo = () => (
  <Icon viewBox='0 0 24 24' w='24px' h='24px'>
    <rect width='24' height='24' rx='2' fill='white' />
    <path
      d='M11.4297 17.5H9.04688V6.07812H15.5938V8.0625H11.4297V11.0078H15.3047V12.9844H11.4297V17.5Z'
      fill='#0B0B0B'
    />
  </Icon>
);

// noinspection JSUnusedGlobalSymbols
export const SearchIcon = () => (
  <Icon viewBox='0 0 21 20' w='21px' h='20px'>
    <circle cx='8.25' cy='8.25' r='7.75' stroke='black' fill='none' />
    <line x1='14.0378' y1='13.3307' x2='20.3535' y2='19.6464' stroke='black' />
  </Icon>
);
