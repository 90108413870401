import axios from 'axios';
import { v4 as uuid } from 'uuid';

export const upload = async ({ file }: { file: File }) => {
  const body = new FormData();
  body.append('bucket', 'funwoo-assets');
  body.append('file', file);
  body.append('filename', `assets/media-exposure/${uuid()}.jpg`);
  const { data } = await axios.post(
    '/rewrite/uploadImage?type=uploadImg',
    body
  );
  const [imageUrl] = data.result;
  return imageUrl;
};
