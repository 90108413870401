import * as Amplitude from 'amplitude-js';

import { isBrowser } from '@src/utils/isBrowser';

let amplitude: typeof Amplitude | null = null;
const AMPLITUDE_EVENT = {
  MENU_CLICK: 'menu_click',
  ADS_CREATE_SUBMIT: 'ads_create_submit_click',
};

const initAmplitude = () => {
  if (isBrowser) {
    // eslint-disable-next-line global-require
    amplitude = Amplitude;
    amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY!);
  }
};

const logEvent = ({
  name,
  properties = {},
}: {
  name: string;
  properties?: Record<string, any>;
}) => {
  if (isBrowser && amplitude) {
    amplitude.getInstance().logEvent(name, properties);
  }
};

const getDeviceId = () => {
  if (isBrowser && amplitude) {
    return amplitude.getInstance().options.deviceId;
  }
  return '';
};

export { amplitude, AMPLITUDE_EVENT, initAmplitude, logEvent, getDeviceId };
