import { v4 as uuid } from 'uuid';
import { FunwooAPI } from '@src/swagger';
import { UploadToGoogleSpecificBucketDto } from '@src/swagger/funwoo.api';

export const imageUploader = async ({
  file,
  bucket,
}: Omit<UploadToGoogleSpecificBucketDto, 'filename'>): Promise<
  Array<string>
> => {
  try {
    const { data } = await FunwooAPI.asset.uploadToGoogleSpecificBucket({
      file,
      bucket,
      filename: uuid(),
    });
    return data;
  } catch (ex) {
    console.error('ex:::', ex);
    throw ex;
  }
};
