export function getBase64FromImageElement(img: HTMLImageElement) {
  const canvas = document.createElement('canvas')!;
  canvas.width = img.width;
  canvas.height = img.height;
  const ctx = canvas.getContext('2d')!;
  ctx.drawImage(img, 0, 0);
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        resolve(blob);
      } else {
        reject('Image converting failed.');
      }
    });
  });
  // return canvas
  //   .toDataURL('image/png')
  //   .replace(/^data:image\/(png|jpg);base64,/, '');
  // return canvas.toDataURL('image/png');
}
