import React, { useContext } from 'react';

import { Flex } from '@chakra-ui/react';

import { SWRConfig } from 'swr';

import { isNotTrue } from '@src/utils';

import { BackyardGlobalContext } from 'src/context';

import ConditionalFragment from '@src/component/common/ConditionalFragment';

import FunwooSpinner from '@src/component/feature/FunwooSpinner';

import Drawer from '@src/component/layout/Drawer';

const fetcher = (...arg: Parameters<typeof fetch>) =>
  fetch(...arg).then((res) => res.json());

const BackyardPage: CommonComponent = ({ children }) => {
  const { user } = useContext(BackyardGlobalContext);

  if (!user) {
    return null;
  }

  return (
    <React.Fragment>
      <SWRConfig value={{ fetcher }}>{children}</SWRConfig>
    </React.Fragment>
  );
};

export default BackyardPage;

export const BackyardLayout: CommonComponent<{ withoutDrawer?: boolean }> = ({
  children,
  withoutDrawer,
}) => {
  return (
    <Flex className='backyard' align='center' w='100vw' h='100vh'>
      <ConditionalFragment condition={isNotTrue(withoutDrawer)}>
        <Drawer />
      </ConditionalFragment>
      {children}
      <FunwooSpinner />
    </Flex>
  );
};
