import type { NextApiRequest, NextApiResponse } from 'next';

import jwt_decode from 'jwt-decode';

import { FUNWOO_TOKEN_KEY } from '@src/constant';

export const permissionHelper = async (req: NextApiRequest, res: NextApiResponse) => {
  try {
    const userInfo = jwt_decode(req.cookies[FUNWOO_TOKEN_KEY]) as UserInfoProps;
    if (userInfo.email.endsWith('@funwoo.com.tw')) {
      return true;
    } else {
      res.status(400).json({ error: 'permission is not allowed' });
      return false;
    }
  } catch (error) {
    res.status(400).json({ error: 'permission is not allowed' });
    return false;
  }
};
