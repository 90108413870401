import { ContractTypeEnum } from '@src/swagger/funwoo.api';

export const detail_category_options = [
  { value: '公寓', label: '公寓' },
  { value: '大樓', label: '大樓' },
  {
    value: '華廈',
    label: '華廈',
  },
  { value: '別墅 / 透天厝', label: '別墅 / 透天厝' },
  { value: '土地', label: '土地' },
  { value: '其他', label: '其他' },
];

export const detail_sorting_categories_options = [
  { value: '住宅', label: '住宅' },
  { value: '店面', label: '店面' },
  {
    value: '辦公',
    label: '辦公',
  },
  { value: '土地', label: '土地' },
  { value: '其他', label: '其他' },
];

export const contract_type_options = [
  {
    value: ContractTypeEnum.COMMON,
    label: 'listing.contract_type_enum.COMMON',
  },
  {
    value: ContractTypeEnum.UNIQUE,
    label: 'listing.contract_type_enum.UNIQUE',
  },
];

export const parking_space_type_options: Array<Option> = [
  { value: '平面', label: '平面' },
  { value: '坡道平面', label: '坡道平面' },
  { value: '坡道機械', label: '坡道機械' },
  { value: '機械平面', label: '機械平面' },
  { value: '倉儲 / 塔式車位', label: '倉儲 / 塔式車位' },
  { value: '其他', label: '其他' },
];
