import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { useDisclosure } from '@chakra-ui/react';

const ListingEditorDraftSubmitModalContext = createContext(
  {} as {
    isDraftSubmitOpen: boolean;
    onDraftSubmitOpen: () => void;
    isDraftSubmitClose: () => void;

    isSubmitting: boolean;
    setIsSubmitting: Dispatch<SetStateAction<boolean>>;
  }
);
export const ListingEditorDraftSubmitModalContextProvider: CommonComponent = ({
  children,
}) => {
  const {
    isOpen: isDraftSubmitOpen,
    onOpen: onDraftSubmitOpen,
    onClose: isDraftSubmitClose,
  } = useDisclosure();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  return (
    <ListingEditorDraftSubmitModalContext.Provider
      value={{
        isDraftSubmitOpen,
        onDraftSubmitOpen,
        isDraftSubmitClose,

        isSubmitting,
        setIsSubmitting,
      }}
    >
      {children}
    </ListingEditorDraftSubmitModalContext.Provider>
  );
};
export const useListingEditorDraftSubmitModalContext = () => {
  return useContext(ListingEditorDraftSubmitModalContext);
};
