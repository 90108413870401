import { revalidate, revalidateMobile } from '@src/fetcher';

import { FunwooAPI } from '@src/swagger';
import { isNotSet } from '@src/utils/formatChecker';

export const listingRevalidator = async (sid: string | undefined) => {
  if (isNotSet(sid)) return;

  const { data: agentSids } = await FunwooAPI.agentApi.findAllSids();

  const agentsPaths = agentSids.map((agentSid) => `/buy/${sid}/${agentSid}`);

  await Promise.all([
    revalidate([
      `/buy/${sid}`,
      `/buy/${sid}/map`,
      `/buy/${sid}/street`,
      ...agentsPaths,
    ]),
    revalidateMobile([
      `/buy/${sid}`,
      `/buy/${sid}/map`,
      `/buy/${sid}/street`,
      ...agentsPaths,
    ]),
  ]).catch((e) => console.error(e));
};

export const agentRevalidator = async (sid: string | undefined) => {
  if (isNotSet(sid)) return;

  await Promise.all([
    revalidate([`/agent/${sid}`]),
    revalidateMobile([`/agent/${sid}`]),
  ]).catch((e) => console.error(e));
};
