export const getImageSizeFromURL = (src: string) =>
  new Promise<{
    width: number;
    height: number;
  }>((resolve) => {
    const img = document.createElement('img');
    img.style.position = 'absolute';
    img.style.top = '-999999px';
    img.style.left = '-999999px';
    img.style.maxWidth = 'fit-content';
    img.src = src;
    img.onload = function () {
      resolve({
        width: img.width,
        height: img.height,
      });
      img.remove();
    };
    document.body.appendChild(img);
  });
