import { jsPDF } from 'jspdf';

export async function createPdf(
  pages: Array<HTMLCanvasElement>,
  { width = 210, height = 297, x = 0, y = 0 } = {},
  filename: string = 'a4'
) {
  /* eslint-disable-next-line new-cap */
  const doc = new jsPDF();
  pages.forEach((page, index) => {
    const orientation = page.width > page.height ? 'l' : 'p';

    if (index !== 0) {
      doc.addPage('a4', orientation);
    }
    if (!page) return;

    doc.addImage(
      page,
      'JPEG',
      x,
      y,
      orientation === 'l' ? height : width,
      orientation === 'l' ? width : height
    );
  });
  doc.save(`${filename}.pdf`);
}
