export const pathname = {
  backyard: '/',
  listingPresentation: '/listing-presentation',
  socialMediaAssets: '/social-media-assets',
  salesDashboard: '/sales-dashboard',
  adsCenter: '/ads',
  CMA: '/cma-report',
  publicationManager: '/publication-manager',
  templateManager: '/template-manager',
  onePageFlyer: '/one-page-flyer',
  admin: '/admin',
  listing: '/listing',
  listingSorting: '/listing-sorting',
  buildings: '/buildings',
  buildingSorting: '/building-sorting',
  referralManager: '/referral-manager',
  userRole: '/user-role',
  filter: '/filter',
  agents: '/agents',
  configSetting: '/config-setting',
};
