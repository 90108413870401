import differenceInYears from 'date-fns/differenceInYears';

export const getAgeYear = (date: string = '') => {
  return Math.abs(differenceInYears(new Date(date), new Date()));
};

export const dateCodeToString = (code: string) => {
  return code
    ? '民國' +
        code.substr(0, 3) +
        '年' +
        code.substr(3, 2) +
        '月' +
        code.substr(5, 2) +
        '日'
    : '';
};
