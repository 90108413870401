import '../styles/globals.css';
import 'react-toastify/dist/ReactToastify.min.css';

import { NextComponentType, NextPageContext } from 'next';
import { appWithTranslation } from 'next-i18next';
import NextProgress from 'nextjs-progressbar';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import React, { useEffect, useRef } from 'react';

import { Box } from '@chakra-ui/react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';

import classNames from 'classnames';

import { BackyardGlobalContextProvider } from '@src/context';

import FunwooToastContainer from '@src/component/feature/FunwooToastContainer';

import { BackyardLayout } from '@src/component/layout/Page';
import Themed from '@src/component/layout/Theme';
import ErrorFallback from '@src/component/feature/ErrorFallback';

type IComponent = NextComponentType<NextPageContext, any> & {
  fullScreenLayout?: boolean;
};

interface IAppProps extends AppProps {
  Component: IComponent;
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    enabled: process.env.RUNTIME_ENV !== 'development',
    // @ts-ignore
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: `backyard-web@${process.env.RUNTIME_ENV}`,
  });
}

function MyApp({ Component, pageProps }: IAppProps) {
  const boxRef = useRef<HTMLDivElement>(null);
  const { asPath } = useRouter();

  useEffect(() => {
    if (boxRef.current?.scrollTo) {
      boxRef.current?.scrollTo({ left: 0, top: 0 });
    }
  }, [asPath]);

  // noinspection RequiredAttributes
  return (
    <Themed>
      <Sentry.ErrorBoundary fallback={ErrorFallback}>
        <BackyardGlobalContextProvider>
          <BackyardLayout withoutDrawer={Component?.fullScreenLayout}>
            <NextProgress color={'black'} />
            <Box
              id={'main'}
              className={classNames(
                'relative',
                'flex-1 flex-shrink-0',
                'h-screen max-h-screen',
                'overflow-hidden',
                'bg-white'
              )}
              ref={boxRef}
            >
              <Component {...pageProps} />
              <FunwooToastContainer />
            </Box>
          </BackyardLayout>
        </BackyardGlobalContextProvider>
      </Sentry.ErrorBoundary>
    </Themed>
  );
}

export default appWithTranslation(MyApp);
