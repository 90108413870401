import { isNotEmptyString } from '@src/utils/formatChecker';
import * as htmlToImage from 'html-to-image';
import html2canvas from 'html2canvas';

const DOWNLOAD_FILENAME = 'download.png';

export const HTML2CANVAS_UNSUPPORTED_FONT_FAMILY = ['"Noto Sans TC"'];
export const OFFSET_FONT_SIZE_STARTER = 12;
export const OFFSET_STARTER = 4.5;
export const OFFSET_BASE = 0.425;

export const lineHeightCorrector = (children: HTMLCollection) => {
  [...children].forEach((child) => {
    const style = window.getComputedStyle(child);
    if (HTML2CANVAS_UNSUPPORTED_FONT_FAMILY.includes(style.fontFamily)) {
      const lineHeight = parseInt(style.lineHeight, 10);
      const height = parseInt(style.height, 10);
      const fontSize = parseInt(style.fontSize, 10);
      // const lineNumber = height / lineHeight;
      const offset =
        ((Math.max(lineHeight, fontSize) - OFFSET_STARTER) / 2) * OFFSET_BASE +
        OFFSET_STARTER;
      // console.log(
      //   {
      //     lineHeight,
      //     offset,
      //     height,
      //     fontSize,
      //   }
      //   //   lineHeight / 2 - fontSize
      // );
      // (child as HTMLDivElement).style.top = '0px';
      // (child as HTMLDivElement).style.backgroundColor = 'red';
      // (child as HTMLDivElement).style.height = `${height + offset}px`;
      // (child as HTMLDivElement).style.fontSize = `14px`;
      // (child as HTMLDivElement).style.lineHeight = `14px`;
      (child as HTMLDivElement).style['marginTop'] = `-${offset}px`;
    }
  });
};

export const imageQualityUpgrade = (children: HTMLCollection) => {
  [...children].forEach((child) => {
    const style = window.getComputedStyle(child);
    if (isNotEmptyString(style.backgroundImage)) {
      const regex = /url\("(.*)"\)/;
      const url = regex.exec(style.backgroundImage)?.[1] ?? '';
      if (isNotEmptyString(url)) {
        const image = document.createElement('img');
        const className = child.className;

        image.className = className;
        image.src = url;
        child.replaceWith(image);
      }
    }
  });
};

export enum DownloadImageMethodType {
  'node2canvas' = 'node2canvas',
  'html2image' = 'html2image',
}

export async function downloadImage({
  node,
  filename = DOWNLOAD_FILENAME,
  type = DownloadImageMethodType.node2canvas,
}: {
  node: HTMLElement;
  filename: string;
  type?: DownloadImageMethodType;
}) {
  if (!node) {
    return;
  }

  const clone = node.cloneNode(true) as HTMLElement;
  clone.style.transform = 'scale(1)';
  clone.style['transformOrigin'] = 'top';

  // const style = document.createElement('style');

  // language=css
  const stylesheet = `
      /* Hide scrollbar for Chrome, Safari and Opera */
      *::-webkit-scrollbar {
          display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      * {
          -ms-overflow-style: none; /* IE and Edge */
          scrollbar-width: none; /* Firefox */
      }
  `;
  clone.insertAdjacentHTML(
    'afterbegin',
    `<style type="text/css">${stylesheet}</style>`
  );

  document.body.appendChild(clone);
  let href = '';

  if (type === DownloadImageMethodType.html2image) {
    href = await htmlToImage.toJpeg(clone);
  } else if (type === DownloadImageMethodType.node2canvas) {
    // Mainly to handle the font-family that html2canvas not supported.
    // If html2canvas not support your font-family then your text may will shift down.
    // Conclusion:
    // 1. The font-size will not affect the position.(otherwise the font-size larger than line-height).
    lineHeightCorrector(clone.children);
    const canvas = await html2canvas(clone, {
      useCORS: true,
      scale: 1,
    });
    canvas.style.position = 'absolute';
    canvas.style.top = '0px';
    document.body.appendChild(canvas);
    href = canvas.toDataURL();
    canvas.remove();
  }

  const link = document.createElement('a');

  link.href = href;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
  document.body.removeChild(clone);
}
