/**
 * To accommodate existing action
 * TODO(huge) drop ACTION in favor of REST_ACTION
 */
export enum ACTION {
  LIST = 'list',
  MULTI = 'multi',
  SINGLE = 'single',
  SINGLE_TYPE = 'type',
}

export enum TEMPLATE_CATEGORY {
  'ALL' = 'ALL',
  'LISTING_PRESENTATION' = 'LISTING_PRESENTATION',
  'SOCIAL_MEDIA' = 'SOCIAL_MEDIA',
  'ONE_PAGE_FLYER' = 'ONE_PAGE_FLYER',
}

export enum HTTP_VERB {
  'CONNECT' = 'CONNECT',
  'DELETE' = 'DELETE',
  'GET' = 'GET',
  'HEAD' = 'HEAD',
  'OPTIONS' = 'OPTIONS',
  'PATCH' = 'PATCH',
  'POST' = 'POST',
  'PUT' = 'PUT',
  'TRACE' = 'TRACE',
}

export enum PUBLICATION_OP {
  'BOOK_TITLE' = 'BOOK_TITLE',
  'BOOK' = 'BOOK',
  'PAGE' = 'PAGE',
  'REORDER' = 'REORDER',
}

export enum BUCKET {
  THUMBNAIL = 'thumbnail',
  GENERAL = 'general',
  TEMPLATE = 'template',
  PUBLICATION = 'publication',
  LISTING_PRESENTATION = 'listing-presentation',
  SOCIAL_MEDIA = 'social-media',
}

export enum TEMPLATE_FIELD_TYPE {
  TEXT = 'string',
  IMAGE = 'image',
}

export enum AD_ACTION {
  CALL_TO_ACTION = 'LEARN_MORE',
  DONE = 'DONE',
}

export enum STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}
