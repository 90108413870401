export const LANDSCAPE_ASPECT_RATIO = 3 / 2;

export const PORTRAIT_ASPECT_RATIO = 1 / LANDSCAPE_ASPECT_RATIO;

export const isPortrait = ({ url }: { url: string }): boolean => {
  return url.indexOf('_h') !== -1;
};

export const getNaturalSize = (
  image: HTMLImageElement | null
): { width: number; height: number } => {
  if (!image) return { width: 0, height: 0 };
  return { width: image.naturalWidth, height: image.naturalHeight };
};
