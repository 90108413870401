import React, { createContext, useCallback, useContext, useState } from 'react';

import { NOOP } from '@src/constant';

import { loadingEventEmitter } from '@src/event';

import { useJWT } from '@src/hooks/useJWT';
import { FunwooAPI } from '../swagger';
import {
  BackyardUserEntity,
  Permission,
  UserRole,
} from '../swagger/funwoo.api';
import { isNotEmptyString } from '@src/utils';
import { useAsync } from 'react-use';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

interface IBackyardGlobalContext {
  isMenuOpen: boolean;
  isConnectedToInstagram: boolean;
  isConnectedToFacebook: boolean;
  user: null | UserInfoProps;
  userInfo: BackyardUserEntity | Permission | null;
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setLoading: (loading: boolean) => void;
  reloadUser: () => void;
  loaderWrapper: <
    Callback extends (...arg: Array<any>) => PromiseLike<unknown> | unknown,
    Arguments extends Parameters<Callback> = Parameters<Callback>,
    Return extends ReturnType<Callback> = ReturnType<Callback>
  >(
    callback: Callback
  ) => (...arg: Arguments) => Return;
  userRole: UserRole[];
}

export const BackyardGlobalContext = createContext({
  // State
  isMenuOpen: true,
  isConnectedToInstagram: false,
  isConnectedToFacebook: false,
  user: null,
  userInfo: null,
  userRole: [],

  // Method
  setIsMenuOpen: NOOP,
  setLoading: NOOP,
  reloadUser: NOOP,
  loaderWrapper: NOOP,
} as IBackyardGlobalContext);
export const BackyardGlobalContextProvider: CommonComponent = ({
  children,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(true);
  const [reloadUserCount, setReloadUserCount] = useState<number>(0);

  const user = useJWT();
  const router = useRouter();

  const {
    value: {
      userInfo,
      isConnectedToInstagram,
      isConnectedToFacebook,
      userRole,
    } = {
      userInfo: null,
      isConnectedToInstagram: false,
      isConnectedToFacebook: false,
      userRole: [],
    },
  } = useAsync(async () => {
    if (router.route === '/auth/signin') return undefined;

    let userRole: Array<UserRole> = [];
    let isConnectedToInstagram: boolean = false;
    let isConnectedToFacebook: boolean = false;
    let userInfo: IBackyardGlobalContext['userInfo'] = null;

    if (user) {
      const { data } = await FunwooAPI.permissionApi.findOne();
      const { user_role, instagram_token, instagram_id, facebook_oauth } = data;

      userRole = user_role;
      isConnectedToInstagram =
        isNotEmptyString(instagram_id ?? '') &&
        isNotEmptyString(instagram_token ?? '');
      isConnectedToFacebook = facebook_oauth;
      userInfo = data;
    }

    return {
      userRole,
      isConnectedToFacebook,
      isConnectedToInstagram,
      userInfo,
    };
  }, [user, reloadUserCount]);

  const reloadUser = useCallback(
    () => setReloadUserCount((prev) => prev + 1),
    []
  );

  const setLoading = useCallback((loading: boolean) => {
    loadingEventEmitter.emit(loading);
  }, []);

  const loaderWrapper = useCallback<IBackyardGlobalContext['loaderWrapper']>(
    // @ts-ignore
    (callback) => {
      return async (...arg) => {
        try {
          setLoading(true);
          return await callback(...arg);
        } catch (e) {
          toast.error(JSON.stringify(e));
        } finally {
          setLoading(false);
        }
      };
    },
    []
  );

  return (
    <BackyardGlobalContext.Provider
      value={{
        isMenuOpen,
        user,
        userInfo,
        setIsMenuOpen,
        setLoading,
        reloadUser,
        loaderWrapper,
        userRole: userRole,
        isConnectedToInstagram,
        isConnectedToFacebook,
      }}
    >
      {children}
    </BackyardGlobalContext.Provider>
  );
};
// Promise<any>' is assignable to the constraint of type 'Return', but 'Return' could be instantiated with a different subtype of constraint 'Promise<any>'.

export const useBackyardGlobalContext = () => useContext(BackyardGlobalContext);
