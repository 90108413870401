export const FUNWOO_TOKEN_KEY = 'Authorization';

export const API_SCHEMA = {
  OPF: [
    'address',
    'pattern',
    'detail_total_area_size',
    'price',
    'listing_image',
    'detail_purpose',
    'detail_category',
    'opf_floor',
    'opf_dateOfCompleted',
    'opf_documentSize',
    'detail_main_area_size',
    'detail_other_area_size',
    'detail_amenities_area',
    'detail_parking_size',
    'opf_parkingCount',
    'opf_lotSizeLabel',
    'detail_lot_size',
    'detail_guard_management',
    'detail_management_fee',
    'detail_amenities',
    'primaryAgentPhoto',
    'primaryAgentChineseName',
    'primaryAgentEnglishName',
    'primaryAgentPhone',
    'primaryAgentEmail',
    'primaryAgentLineQRCode',
    'primaryAgentLicence',
    'secondaryAgentPhoto',
    'secondaryAgentChineseName',
    'secondaryAgentEnglishName',
    'secondaryAgentPhone',
    'secondaryAgentEmail',
    'secondaryAgentLineQRCode',
    'secondaryAgentLicence',
    'feature_content',
  ],
  HOUSE: [
    'address',
    'bath',
    'buildingProject',
    'city',
    'commonSpace',
    'displayAddress',
    'displayShortID',
    'district',
    'favorites',
    'itemFeature.content',
    'itemFeature.tags',
    'picture.imageUrl',
    'picture.largeImageUrl',
    'price',
    'room',
    'status',
    'title',
    'totalFloorArea',
  ],
  AGENT: [
    'active',
    'bio',
    'chinese_name',
    'contact.fb',
    'contact.ig',
    'contact.line',
    'contact.phone',
    'email',
    'english_name',
    'license',
    'name',
    'pictures',
  ],
};

export const countryUnitMap: Map<
  string,
  {
    areaUnit: string;
    areaLabel: string;
    dollar: string;
    dollarLabel: string;
  }
> = new Map([
  [
    'US',
    {
      areaUnit: '平方英呎',
      areaLabel: '總面積',
      dollar: '美金',
      dollarLabel: '總價位 (美金)',
    },
  ],
  [
    'CA',
    {
      areaUnit: '平方英呎',
      areaLabel: '總面積',
      dollar: '加幣',
      dollarLabel: '總價位 (加幣)',
    },
  ],
  [
    'TW',
    {
      areaUnit: '坪',
      areaLabel: '總坪數',
      dollar: '',
      dollarLabel: '總價位',
    },
  ],
]);
