import { TemplatePageDataEntity } from '@src/swagger/funwoo.api';
import { TEMPLATE_FIELD_TYPE } from '@src/constant';

export const populateDataToHtml = (
  body: string = '',
  dataArray: Array<TemplatePageDataEntity> = [],
  isLargeMode: boolean = false
) => {
  let html = body;

  dataArray.map((data) => {
    if (data.type === TEMPLATE_FIELD_TYPE.TEXT) {
      html = html.replace(`$${data.id}<`, `${data.value}<`);
    } else if (data.type === TEMPLATE_FIELD_TYPE.IMAGE) {
      if (html.includes(`$${data.id}`)) {
        html = html.replace(
          `$${data.id}`,
          isLargeMode ? `${data.value_l}` : `${data.value}`
        );
      } else {
        html = html.replace(
          `${data.id}`,
          isLargeMode ? `${data.value_l}` : `${data.value}`
        );
      }
    }
  });

  const embedReg = /<(link .* rel="stylesheet")>/;
  while (html.match(embedReg)) {
    const matched = embedReg.exec(html)?.[1];
    if (matched) {
      html = html.replace(matched, `${matched} crossorigin="anonymous"`);
    } else {
      break;
    }
  }
  return html;
};
