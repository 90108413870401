import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { NOOP, pathname } from '@src/constant';

import { isEmptyArray } from '@src/utils';

import { FunwooAPI } from '@src/swagger';
import { TemplateEntity } from '@src/swagger/funwoo.api';

interface TemplateState {
  templateId?: string;
  templates: Array<TemplateEntity>;
  currentTemplate: TemplateEntity | null;
  imageRef: React.MutableRefObject<Record<string, HTMLInputElement>>;
  updateTemplates: (templateId: string, data: Partial<TemplateEntity>) => void;
  insertTemplate: (...data: Array<TemplateEntity>) => void;
  removeTemplate: (templateId: string) => void;
  saveTemplate: (data: Partial<TemplateEntity>) => void;
}

export const TemplateContext = createContext<TemplateState>({
  templates: [],
  imageRef: { current: {} },
  currentTemplate: null,
  updateTemplates: NOOP,
  insertTemplate: NOOP,
  removeTemplate: NOOP,
  saveTemplate: NOOP,
});

export const TemplateProvider: CommonComponent<{
  templateId?: string;
  defaultTemplates: Array<TemplateEntity>;
}> = ({ children, defaultTemplates, templateId }) => {
  const [templates, setTemplates] =
    useState<Array<TemplateEntity>>(defaultTemplates);
  const router = useRouter();
  const imageRef = useRef<Record<string, HTMLInputElement>>({});

  useEffect(() => {
    if (isEmptyArray(templates)) return;
    if (!templateId)
      router.replace(`${pathname.templateManager}/${templates[0].id}`);
  }, [templateId, templates]);

  const updateTemplates = useCallback(
    (templateId: string, data: Partial<TemplateEntity>) => {
      setTemplates((prev) =>
        prev.map((template) =>
          template.id === templateId ? { ...template, ...data } : template
        )
      );
    },
    []
  );

  const insertTemplate = useCallback((...data: Array<TemplateEntity>) => {
    setTemplates((prev) => [...prev, ...data]);
  }, []);

  const removeTemplate = useCallback((templateId: string) => {
    setTemplates((prev) =>
      prev.filter((template) => template.id !== templateId)
    );
  }, []);

  const currentTemplate = useMemo(
    () => templates?.find((template) => template.id === templateId) || null,
    [templateId, templates]
  );

  const saveTemplate = useCallback(
    async (data: Partial<TemplateEntity>) => {
      if (!templateId || !currentTemplate) return;
      const template = { ...currentTemplate, ...data };
      const syncedTemplate = await FunwooAPI.templateApi
        .update({
          ...template,
          templateId,
        })
        .then((res) => res.data);
      updateTemplates(syncedTemplate.id, syncedTemplate);
    },
    [templateId, currentTemplate]
  );

  return (
    <TemplateContext.Provider
      value={{
        templates,
        templateId,
        imageRef,
        currentTemplate,
        updateTemplates,
        insertTemplate,
        removeTemplate,
        saveTemplate,
      }}
    >
      {children}
    </TemplateContext.Provider>
  );
};
