import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { toast } from 'react-toastify';
import { useAsync, useAsyncFn } from 'react-use';

import { NOOP } from '@src/constant';

import { isNotSet } from '@src/utils';

import { BackyardGlobalContext } from '@src/context/BackyardGlobalContext';

import { FunwooAPI } from '@src/swagger';
import { Book, BookPageEntity, TemplateEntity } from '@src/swagger/funwoo.api';

type WaitingReaction = 'TOAST' | 'LOADER' | 'NONE';

interface BookEditorState {
  bookId?: string | null;
  pageId?: string | null;
  book: Book | null;
  page: BookPageEntity | null;
  pageTemplate: TemplateEntity | null;
  onPageSelect: (pageId: string) => void;
}

const BookEditorContext = createContext<BookEditorState>({
  book: null,
  page: null,
  pageTemplate: null,
  onPageSelect: NOOP,
});

export const BookEditorProvider: CommonComponent<{
  bookId: string;
  pageId: string;
  locally?: boolean;
  skipThumbnailUpdate?: boolean;
  pageRouter?: (bookId: string, pageId: string) => void;
}> = ({ children, bookId, pageId, pageRouter }) => {
  const { loaderWrapper } = useContext(BackyardGlobalContext);

  const { value: templates } = useAsync(async () => {
    const { data: templates } = await FunwooAPI.templateApi.findAll();

    return templates.reduce((previous, current) => {
      const clone = new Map(previous);
      clone.set(current.id, current);
      return clone;
    }, new Map<string, TemplateEntity>());
  }, []);

  const [{ value: book = null }, bookLoader] = useAsyncFn(
    async ({
      defaultBook,
      waitingReaction = 'LOADER',
    }: {
      defaultBook?: Book;
      waitingReaction?: WaitingReaction;
    }) => {
      const callback = async () => {
        if (defaultBook) return defaultBook;

        return await FunwooAPI.bookApi
          .findOneByBookId(bookId)
          .then((res) => res.data);
      };

      if (waitingReaction === 'TOAST') {
        return await toast.promise(callback, {
          pending: 'Updating...',
          success: 'Update Success!',
          error: 'Update Failed!',
        });
      } else if (waitingReaction === 'LOADER') {
        return await loaderWrapper(callback)();
      } else {
        return await callback();
      }
    },
    [bookId]
  );

  useEffect(() => {
    return () => {
      bookLoader({ waitingReaction: 'LOADER' });
    };
  }, [bookLoader]);

  const page = useMemo(() => {
    if (!book) return null;
    return book.pages!.find((page) => page.id === pageId)!;
  }, [pageId, book]);

  const pageTemplate = useMemo(
    () => (page && templates ? templates.get(page.id)! : null),
    [templates, page]
  );

  useEffect(() => {
    if (!bookId) return;
    if (isNotSet(book)) return;
    if (isNotSet(pageId)) {
      pageRouter?.(bookId, book.pages?.[0].id ?? '');
    }
  }, [pageId, book]);

  const onPageSelect = useCallback(
    (pageId: string) => {
      pageRouter?.(bookId, pageId);
    },
    [bookId]
  );

  // const saveBook = useCallback((data: BookPageDataEntity) => {
  //   return;
  // }, []);

  return (
    <BookEditorContext.Provider
      value={{
        bookId,
        pageId,
        book,
        page,
        pageTemplate,
        onPageSelect,
      }}
    >
      {children}
    </BookEditorContext.Provider>
  );
};

export const useBookEditorContext = () => useContext(BookEditorContext);
