import html2canvas from 'html2canvas';

import { FunwooAPI } from '@src/swagger';
import { Book, TemplateEntity } from '@src/swagger/funwoo.api';
import { populateDataToHtml } from '@src/utils/populateDataToHtml';
import {
  imageQualityUpgrade,
  lineHeightCorrector,
} from '@src/utils/download-image';

export async function generatePagesForDownload(
  book: Book | null,
  templates: Record<string, TemplateEntity>
): Promise<Array<HTMLCanvasElement>> {
  if (!book) return [];
  const portal = document.querySelector('#portal');

  // 逐頁取回模板+資料並建立元件供顯示(與挑選最終要輸出的頁面)
  const pages = await Promise.all(
    // 遍歷每一頁
    book.pages!.map(async (p) => {
      // if(idx != 0) return // debug: 只生成第一頁

      // 先確定 templates 已取回，沒有模板的話先取回來
      if (!templates[p.templateId!]) {
        try {
          const { data: payload } = await FunwooAPI.templateApi.findOne(
            p.templateId!
          ); // eslint-disable-line
          templates[payload.id] = payload;
        } catch (e) {
          throw e;
        }
      }

      // 等模板與資料到位，就填入模板，並生成元件
      const tpl = templates[p.templateId!];
      const { body } = tpl;

      // 列印時要抓大版圖
      const filled = populateDataToHtml(body!, p.data);

      // 調整 iframe size 至無捲動狀態
      const iframe = document.createElement('iframe');

      return await new Promise<HTMLCanvasElement>((resolve) => {
        // 要等 iframe loaded 才能抓圖
        iframe.onload = async () => {
          const contentHolder = iframe.contentWindow!.document.querySelector(
            '.v_1'
          ) as HTMLElement;

          const imageData = p.data.filter((data) => data.type === 'image');

          imageData.forEach((data) => {
            if (!data.className) return;
            const imageContainer = contentHolder.querySelector(
              `.${data.className}`
            ) as HTMLDivElement;
            if (!imageContainer) return;
            imageContainer.style.backgroundImage = `url("${data.value_l}")`;
          });

          // 關掉縮圖才能抓到原版大圖
          contentHolder.style.transform = 'scale(1)';
          contentHolder.style['transformOrigin'] = 'top left';

          // 這是原本讓要用戶選取哪些頁面要列印的對話框
          // 抓出內部頁面實體大小，避免出現 scrollbar
          // const { width, height } = contentHolder.getBoundingClientRect()
          // iframe.contentWindow.document.body.style.margin = 0
          // iframe.contentWindow.document.body.style.height = `${height}px`
          // iframe.contentWindow.document.children[0].style.height = `${height}px`
          // iframe.contentWindow.document.children[0].style.overflow = 'hidden'
          // iframe.style.minWidth = `${width}px`
          // iframe.style.minHeight = `${height}px`
          // iframe.style.border = '1px solid red'

          // 抓圖成為 canvas

          // corrector the line-height of text
          lineHeightCorrector(contentHolder.children);
          // Load images by image for better quality.
          imageQualityUpgrade(contentHolder.children);

          const canvas = await html2canvas(contentHolder, {
            useCORS: true,
            scale: 6,
          });

          // +debug: 塞到頁面下方預覽
          // document.body.appendChild(canvas)

          // 將擷取好的大圖返還出去
          resolve(canvas);
        };

        iframe.srcdoc = filled;
        portal!.appendChild(iframe);
      });
    })
  );

  // 抓完圖就清空暫存檔
  document.querySelector('#portal')!.innerHTML = '';

  return pages;
}
