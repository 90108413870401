import React, { createContext, useCallback, useMemo, useState } from 'react';
import { useAsync } from 'react-use';

import { NOOP } from '@src/constant';

import { FunwooAPI } from '@src/swagger';
import {
  PublicationEntity,
  PublicationPageEntity,
  TemplateEntity,
} from '@src/swagger/funwoo.api';

interface PublicationState {
  bookId?: string;
  pageId?: string;
  publications: Array<PublicationEntity>;
  publication: PublicationEntity | null;
  templates: Array<TemplateEntity>;
  currentPage: PublicationPageEntity | null;
  currentTemplate: TemplateEntity | null;
  updatePublications: (
    publicationId: string,
    data: Partial<PublicationEntity>
  ) => void;
  updatePublication: (data: PublicationEntity) => void;
}

export const PublicationContext = createContext<PublicationState>({
  publications: [],
  publication: null,
  templates: [],
  currentPage: null,
  currentTemplate: null,
  updatePublications: NOOP,
  updatePublication: NOOP,
});

export const PublicationProvider: CommonComponent<{
  bookId?: string;
  pageId?: string;
  defaultPublications?: Array<PublicationEntity>;
  defaultPublication?: PublicationEntity;
}> = ({
  children,
  bookId,
  pageId,
  defaultPublications = [],
  defaultPublication = null,
}) => {
  const [publications, setPublications] =
    useState<Array<PublicationEntity>>(defaultPublications);
  const [publication, setPublication] = useState<PublicationEntity | null>(
    defaultPublication
  );

  const { value: templates = [] } = useAsync(
    async () => await FunwooAPI.templateApi.findAll().then((res) => res.data),
    []
  );

  const updatePublications = useCallback(
    (publicationId: string, data: Partial<PublicationEntity>) => {
      setPublications((prev) =>
        prev.map((publication) =>
          publication.id === publicationId
            ? { ...publication, ...data }
            : publication
        )
      );
    },
    []
  );

  const updatePublication = useCallback((data: PublicationEntity) => {
    setPublication(data);
  }, []);

  const currentPage = useMemo(
    () => publication?.pages!.find((page) => page.id === pageId) || null,
    [publication, bookId, pageId]
  );

  const currentTemplate = useMemo(
    () =>
      templates?.find((template) => template.id === currentPage?.templateId) ||
      null,
    [currentPage, templates]
  );

  return (
    <PublicationContext.Provider
      value={{
        bookId,
        pageId,
        publications,
        publication,
        templates,
        currentPage,
        currentTemplate,
        updatePublications,
        updatePublication,
      }}
    >
      {children}
    </PublicationContext.Provider>
  );
};
