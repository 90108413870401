import QRCode from 'qrcode';

import { TEMPLATE_FIELD_TYPE } from '@src/constant';

import { canvasToBlob, imageUploader, nodeToCanvas } from '@src/utils';

import LineLogo from '@/public/assets/images/line-logo.png';
import { FunwooAPI } from '@src/swagger';
import {
  Agent,
  BookPageDataEntity,
  GoogleBucketEnum,
} from '@src/swagger/funwoo.api';

const hideElement = (el: HTMLElement, id: string = '') => {
  el.id = id;
  el.style.position = 'absolute';
  el.style.top = '0px';
  el.style.left = '0px';
  el.style.zIndex = '-1';
};

const syncImageElement = (source: string) =>
  new Promise<HTMLImageElement>((resolve) => {
    const image = document.createElement('img');
    image.src = source;
    image.onload = () => {
      resolve(image);
    };
  });

const populateData = (body = '', data: Array<BookPageDataEntity> = []) => {
  let result = body;
  data.forEach((d) => {
    if (d.type === TEMPLATE_FIELD_TYPE.TEXT) {
      result = result.replace(`$${d.id}<`, `${d.value}<`);
    } else if (d.type === TEMPLATE_FIELD_TYPE.IMAGE) {
      // social media 時改用大圖做預覽，以解決 blurry 問題
      // listing-presentation 則維持用小圖做預覽，呈現速度比較快(如果用大版它的體積太大會下載較久)
      result = result.replace(`url("$${d.id}")`, `url("${d.value_l}")`);
    }
  });
  return result;
};

export async function genImage() {
  const blob = await nodeToCanvas(document.querySelector('#previewBox')!);
  const [image] = await imageUploader({
    file: blob as File,
    bucket: GoogleBucketEnum.Thumbnail,
  });
  return image;
}

export async function genThumbnail(
  body: string,
  data: Array<BookPageDataEntity>
) {
  const populatedBody = populateData(body, data);

  const imageData = data.filter((data) => data.type === 'image');

  const previewBox = document.createElement('div');

  previewBox.innerHTML = populatedBody;
  const target = previewBox.querySelector('.v_1') as HTMLElement;
  if (!target) return;

  imageData.forEach((data) => {
    if (!data.className) return;
    const imageContainer = target.querySelector(
      `.${data.className}`
    ) as HTMLDivElement;
    if (!imageContainer) return;
    imageContainer.style.backgroundImage = `url("${data.value_l}")`;
  });

  const rect = target.getBoundingClientRect();
  hideElement(previewBox, 'thumbnail-preview-box');
  previewBox.style.width = `${rect.width}px`;
  previewBox.style.height = `${rect.height}px`;
  document.body.appendChild(previewBox);

  const blob = await nodeToCanvas(target);
  const [image] = await imageUploader({
    file: blob as File,
    bucket: GoogleBucketEnum.Thumbnail,
  });
  previewBox.remove();

  return image;
}

export const genLineQRCode = async (agent: Agent) => {
  if (agent.qrcode_picture_line) return agent.qrcode_picture_line;
  if (!agent.contact_line) return '-';

  const height = 640;
  const scale = 0.15;
  const container = document.createElement('canvas');
  hideElement(container, 'qrcode-container');
  document.body.appendChild(container);

  const lineImage = await syncImageElement(LineLogo.src);
  hideElement(lineImage, 'line-logo-container');
  document.body.appendChild(lineImage);

  try {
    await QRCode.toCanvas(container, agent.contact_line, {
      errorCorrectionLevel: 'medium',
      width: height,
      margin: 1,
    });
    const ctx = container.getContext('2d');

    const imageWidth = lineImage.width;
    const imageHeight = lineImage.height;
    //drawImage(image, sx, sy, sWidth, sHeight, dx, dy, dWidth, dHeight)
    const dWidth = height * scale;
    const dHeight = height * scale;
    const dx = height / 2 - dWidth / 2;
    const dy = height / 2 - dHeight / 2;
    ctx?.drawImage(
      lineImage,
      0,
      0,
      imageWidth,
      imageHeight,
      dx,
      dy,
      dWidth,
      dHeight
    );
    const blob = await canvasToBlob(container);

    const [image] = await imageUploader({
      file: blob as File,
      bucket: GoogleBucketEnum.General,
    });

    await FunwooAPI.agentApi.update({
      id: agent.id,
      qrcode_picture_line: image,
    });
    return image;
  } finally {
    container.remove();
    lineImage.remove();
  }
};
