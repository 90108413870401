import React from 'react';
import { PointerSensor, PointerSensorOptions } from '@dnd-kit/core';

function isInteractiveElement(element: Element | null) {
  const interactiveElements = [
    'button',
    'input',
    'textarea',
    'select',
    'option',
  ];
  return !!(
    element?.tagName &&
    interactiveElements.includes(element.tagName.toLowerCase())
  );
}

export class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as 'onPointerDown',
      handler: (
        { nativeEvent: event }: React.PointerEvent,
        { onActivation }: PointerSensorOptions
      ) => {
        return !(
          !event.isPrimary ||
          event.button !== 0 ||
          isInteractiveElement(event.target as Element)
        );
      },
    },
  ];
}
