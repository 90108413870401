import { useEffect, useState } from 'react';

import { Flex, Spinner } from '@chakra-ui/react';

import { loadingEventEmitter } from '@src/event';

const FunwooSpinner: CommonComponent = () => {
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    loadingEventEmitter.on(setLoading);

    return () => {
      loadingEventEmitter.off(setLoading);
    };
  }, []);

  if (!loading) {
    return null;
  }

  return (
    <Flex
      id={'spinner'}
      zIndex={99999}
      pos='fixed'
      left={0}
      top={0}
      w='100vw'
      h='100vh'
      justify='center'
      align='center'
    >
      <Flex pos='absolute' w='100vw' h='100vh' opacity={0.2} bg='#000' />
      <Flex h='100vh' justify='center' align='center' fontSize='4em'>
        <BrandSpinner />
      </Flex>
    </Flex>
  );
};

export default FunwooSpinner;

const BrandSpinner: CommonComponent = (props) => (
  <strong {...props}>
    FUNW
    <Spinner size='xl' emptyColor='gray.200' speed='.8s' thickness='4px' />
    <Spinner size='xl' emptyColor='gray.200' speed='.8s' thickness='4px' />
  </strong>
);
