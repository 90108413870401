import { useTranslation } from 'next-i18next';

import { useCallback } from 'react';

import { TOptions } from 'i18next';

const useT = (namespace?: string) => {
  const ns = namespace ? `${namespace}.` : '';
  // next-i18next file name
  const { t } = useTranslation('common');
  return useCallback((key: string, options: TOptions = {}) => t(`${ns}${key}`, options), [namespace, t]);
};

export default useT;
