import React, { createContext, useContext } from 'react';

const ListingDetailEditorContext = createContext(
  {} as {
    editable: boolean;
    markField: Array<string>;
  }
);
export const ListingDetailEditorContextProvider: CommonComponent<{
  editable: boolean;
  markField: Array<string>;
}> = ({ editable, markField, children }) => {
  return (
    <ListingDetailEditorContext.Provider value={{ editable, markField }}>
      {children}
    </ListingDetailEditorContext.Provider>
  );
};
export const useListingDetailEditorContext = () => {
  return useContext(ListingDetailEditorContext);
};
