import axios from 'axios';

import { isNotSet } from '@src/utils';

export async function revalidate(paths: Array<string> = []) {
  const baseURL =
    process.env.RUNTIME_ENV === 'production'
      ? 'https://funwoo.com.tw'
      : process.env.RUNTIME_ENV === 'staging'
      ? 'https://staging.funwoo.com.tw'
      : 'http://localhost:3005';

  await axios.post(
    `${baseURL}/api/revalidate`,
    { paths },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }
  );
}

export async function revalidateMobile(paths: Array<string> = []) {
  const baseURL =
    process.env.RUNTIME_ENV === 'production'
      ? 'https://m.funwoo.com.tw'
      : process.env.RUNTIME_ENV === 'staging'
      ? 'https://ms.funwoo.com.tw'
      : null;

  if (isNotSet(baseURL)) return;
  await axios.post(
    `${baseURL}/api/revalidate`,
    { paths },
    {
      headers: {
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    }
  );
}
