import numbro from 'numbro';

import { Agent, ListingDetail } from '@src/swagger/funwoo.api';
import { isNotEmptyArray, isNotSet } from '@src/utils/formatChecker';
import { genLineQRCode } from '@src/utils/genImage';

const WAN = 1e4;
const YI = 1e8;

const potionCategory = [
  '公寓',
  '公寓(5樓含以下無電梯)',
  '大樓',
  '住宅大樓(11層含以上有電梯)',
  '華廈',
  '華廈(10層含以下有電梯)',
  '店面',
  '店面(店鋪)',
  '辦公商業大樓',
  '套房(1房1廳1衛)',
];

const getBuildingAge = ({
  year_of_building_completion: buildingYear,
  month_of_building_completion: buildingMonth,
}: ListingDetail) => {
  if (!buildingYear || !buildingMonth) return '-';

  let currentYear = new Date().getFullYear();
  let currentMonth = new Date().getMonth() + 1;

  if (currentYear - buildingYear == 0 && currentMonth - buildingMonth <= 0) {
    return '預計' + buildingYear + '完工';
  } else if (
    currentYear - buildingYear == 0 &&
    currentMonth - buildingMonth > 0
  ) {
    return `${currentMonth - buildingMonth} 個月`;
  } else if (currentYear - buildingYear < 0) {
    return '預計' + buildingYear + '完工';
  } else {
    return `${currentYear - buildingYear} 年`;
  }
};

const formatter = {
  format: (n: number) => {
    return numbro(n).format({ thousandSeparated: true });
  },
};

const countParkingSpace = ({
  parking_detail,
  detail_parking,
}: ListingDetail) => {
  if (parking_detail)
    return `${parking_detail.reduce(
      (prev, curr) => prev + parseInt(String(curr.num), 0),
      0
    )} 個`;
  else if (detail_parking) return `${detail_parking}`;
  else return '-';
};

const countryUnitMap: Map<
  string,
  {
    areaUnit: string;
    areaLabel: string;
    dollar: string;
    dollarLabel: string;
  }
> = new Map([
  [
    'US',
    {
      areaUnit: '平方英呎',
      areaLabel: '總面積',
      dollar: '美金',
      dollarLabel: '總價位 (美金)',
    },
  ],
  [
    'CA',
    {
      areaUnit: '平方英呎',
      areaLabel: '總面積',
      dollar: '加幣',
      dollarLabel: '總價位 (加幣)',
    },
  ],
  [
    'TW',
    {
      areaUnit: '坪',
      areaLabel: '總坪數',
      dollar: '',
      dollarLabel: '總價位',
    },
  ],
]);

const chineseNumeralFormatter = (n: number): string => {
  if (n < YI) {
    return `$ ${formatter.format(n / WAN)} 萬`;
  } else {
    return `$ ${formatter.format(n / YI)} 億`;
  }
};

const unitAreaFormatter = (country: string): string => {
  return `${countryUnitMap.get(country!)?.areaUnit}`;
};
// const labelAreaFormatter = (country: string): string => {
//   return `${countryUnitMap.get(country!)?.areaLabel}`;
// };
const dollarFormatter = (country: string): string => {
  return `${countryUnitMap.get(country!)?.dollar}`;
};

const areaFormatter = (
  input: string | null,
  {
    omit = [],
    country = 'TW',
  }: { omit?: Array<string | null>; country: string }
): string | null | number => {
  if (isNotSet(input)) return null;

  let result = Number.isNaN(parseFloat(input)) ? 0 : parseFloat(input);
  omit.forEach((value) => {
    if (isNotSet(value)) return;
    let num = Number.isNaN(parseFloat(value)) ? 0 : parseFloat(value);
    result = result - num;
  });

  if (country === 'TW') {
    return `${result.toFixed(2)}`;
  } else {
    return `${result}`;
  }
};

export const lotSizeTitleLangKeyFormatter = ({
  detail_category,
}: {
  detail_category?: string | null;
}) => {
  if (potionCategory.some((category) => category === detail_category)) {
    return '土地 (持分) 坪數';
  } else {
    return '土地坪數';
  }
};

const getSizeArea = (
  size: string | null,
  country: string,
  omit: Array<string | null> = []
) =>
  size
    ? `${areaFormatter(size, { country, omit })} ${unitAreaFormatter(country!)}`
    : '-';
export const refSchemaFormatter: Record<
  string,
  (
    data: ListingDetail,
    agents?: { primaryAgent: Agent; secondaryAgent: Agent }
  ) => string | Promise<string>
> = {
  pattern: ({ room, common_space, bath }) => {
    let result = [];
    if (room) result.push(`${room} 房`);
    if (common_space) result.push(`${common_space} 廳`);
    if (bath) result.push(`${bath} 衛`);

    if (isNotEmptyArray(result)) return result.join(' ');
    else return '-';
  },
  detail_total_area_size: ({ detail_total_area_size, country }) => {
    return getSizeArea(detail_total_area_size, country!);
  },
  price: ({ price, country }) =>
    price
      ? `${chineseNumeralFormatter(price)} ${dollarFormatter(country!)}`
      : '-',
  listing_image: ({ listing_image }) => listing_image[0]?.original_image ?? '',
  opf_floor: ({ floor_note, floor, total_floor }) =>
    floor_note
      ? floor_note
      : floor && total_floor
      ? `${floor}樓 / ${total_floor}樓`
      : '-',
  opf_dateOfCompleted: getBuildingAge,
  opf_documentSize: ({
    detail_total_area_size,
    country,
    detail_parking_size,
  }) => {
    return getSizeArea(detail_total_area_size, country!, [detail_parking_size]);
  },
  detail_main_area_size: ({ detail_main_area_size, country }) =>
    getSizeArea(detail_main_area_size, country!),
  detail_other_area_size: ({ detail_other_area_size, country }) =>
    getSizeArea(detail_other_area_size, country!),
  detail_amenities_area: ({ detail_amenities_area, country }) =>
    getSizeArea(detail_amenities_area, country!),
  detail_parking_size: ({ detail_parking_size, country }) =>
    getSizeArea(detail_parking_size, country!),
  opf_parkingCount: countParkingSpace,
  opf_lotSizeLabel: lotSizeTitleLangKeyFormatter,
  detail_lot_size: ({ detail_lot_size, country }) =>
    getSizeArea(detail_lot_size, country!),
  detail_guard_management: ({ detail_guard_management }) =>
    detail_guard_management ? '有' : '無',
  detail_management_fee: ({ detail_management_fee }) =>
    detail_management_fee ?? '-',
  primaryAgentPhoto: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentPhoto'](agent?.primaryAgent)
      : '',
  primaryAgentChineseName: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentChineseName'](agent?.primaryAgent)
      : '',
  primaryAgentEnglishName: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentEnglishName'](agent?.primaryAgent)
      : '',
  primaryAgentPhone: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentPhone'](agent?.primaryAgent)
      : '',
  primaryAgentEmail: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentEmail'](agent?.primaryAgent)
      : '',
  primaryAgentLineQRCode: async (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentLineQRCode'](agent?.primaryAgent)
      : '',
  primaryAgentLicence: async (_, agent) =>
    agent
      ? agentRefSchemaFormatter['primaryAgentLicence'](agent?.primaryAgent)
      : '',
  secondaryAgentPhoto: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentPhoto'](agent?.secondaryAgent)
      : '',
  secondaryAgentChineseName: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentChineseName'](
          agent?.secondaryAgent
        )
      : '',
  secondaryAgentEnglishName: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentEnglishName'](
          agent?.secondaryAgent
        )
      : '',
  secondaryAgentPhone: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentPhone'](agent?.secondaryAgent)
      : '',
  secondaryAgentEmail: (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentEmail'](agent?.secondaryAgent)
      : '',
  secondaryAgentLineQRCode: async (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentLineQRCode'](
          agent?.secondaryAgent
        )
      : '',
  secondaryAgentLicence: async (_, agent) =>
    agent
      ? agentRefSchemaFormatter['secondaryAgentLicence'](agent?.secondaryAgent)
      : '',
};

export const agentRefSchemaFormatter = {
  primaryAgentPhoto: (agent: Agent) => agent.pictures?.[0] ?? '',
  primaryAgentChineseName: (agent: Agent) =>
    `${agent.chinese_name} ${agent.english_name}`,
  primaryAgentEnglishName: (agent: Agent) =>
    `${agent.chinese_name} ${agent.english_name}`,
  primaryAgentPhone: (agent: Agent) => agent.contact_phone ?? '',
  primaryAgentEmail: (agent: Agent) => agent.email,
  primaryAgentLineQRCode: async (agent: Agent) => await genLineQRCode(agent),
  primaryAgentLicence: async (agent: Agent) => `${agent.license}`,
  secondaryAgentPhoto: (agent: Agent) => agent.pictures?.[0] ?? '',
  secondaryAgentChineseName: (agent: Agent) =>
    `${agent.chinese_name} ${agent.english_name}`,
  secondaryAgentEnglishName: (agent: Agent) =>
    `${agent.chinese_name} ${agent.english_name}`,
  secondaryAgentPhone: (agent: Agent) => agent.contact_phone ?? '',
  secondaryAgentEmail: (agent: Agent) => agent.email,
  secondaryAgentLineQRCode: async (agent: Agent) => await genLineQRCode(agent),
  secondaryAgentLicence: async (agent: Agent) => `${agent.license}`,
};
