import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';
import { useAsyncFn } from 'react-use';

import debounce from 'lodash/debounce';
import keyBy from 'lodash/keyBy';
import { useRouter } from 'next/router';

import { NOOP, TEMPLATE_CATEGORY } from '@src/constant';

import { deepClone, genThumbnail, isNotSet, isSet } from '@src/utils';

import { FunwooAPI } from '@src/swagger';
import {
  Book,
  BookPageDataEntity,
  BookPageEntity,
  TemplateEntity,
  UpdateBookPageDto,
  UpdateWholeBookPageDto,
} from '@src/swagger/funwoo.api';

import { BackyardGlobalContext } from './BackyardGlobalContext';

type WaitingReaction = 'TOAST' | 'LOADER' | 'NONE';

interface IPageEditorContext {
  // State
  bookId?: string;
  pageId?: string;
  selectedBook: Book | null;
  selectedTemplate: TemplateEntity | null;
  selectedPage: BookPageEntity | null;
  allTemplates: Record<string, TemplateEntity>;

  // Method
  reloadBookHandler: (config: {
    defaultTemplates?: Record<string, TemplateEntity>;
    defaultBook?: Book;
    waitingReaction?: WaitingReaction;
  }) => Promise<{
    allTemplates: Record<string, TemplateEntity>;
    selectedBook: Book | null;
  }>;
  onPageSelect: (pageId: string) => void;
  debouncedSaveBook: (
    item: BookPageDataEntity,
    useCurrentThumbnail?: boolean
  ) => void;
  saveBook: (item: BookPageDataEntity, useCurrentThumbnail?: boolean) => void;
  saveWholePage: (item: Array<BookPageDataEntity>) => void;
}

export const PageEditorContext = createContext<IPageEditorContext>({
  // State
  bookId: '',
  pageId: '',
  selectedBook: null,
  selectedTemplate: null,
  selectedPage: null,
  allTemplates: {},

  // Method
  reloadBookHandler: NOOP,
  onPageSelect: NOOP,
  debouncedSaveBook: NOOP,
  saveBook: NOOP,
  saveWholePage: NOOP,
});

export const PageEditorProvider: CommonComponent<{
  pathname: string;
  bookId?: string;
  pageId?: string;
  neverSync?: boolean;
  skipThumbnailUpdate?: boolean;
  defaultBook?: Book;
  defaultTemplates?: Array<TemplateEntity>;
}> = ({
  children,
  pathname,
  bookId,
  pageId,
  neverSync,
  skipThumbnailUpdate,
  defaultTemplates,
  defaultBook,
}) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<TemplateEntity | null>(null);
  const [selectedPage, setSelectedPage] = useState<BookPageEntity | null>(null);

  const { setLoading } = useContext(BackyardGlobalContext);
  const router = useRouter();

  const [
    {
      value: { allTemplates, selectedBook } = {
        allTemplates: {} as Record<string, TemplateEntity>,
        selectedBook: null,
      },
    },
    reloadBookHandler,
  ] = useAsyncFn(
    async (
      {
        defaultTemplates,
        defaultBook,
        waitingReaction,
      }: {
        defaultTemplates?: Record<string, TemplateEntity>;
        defaultBook?: Book;
        waitingReaction?: WaitingReaction;
      } = {
        defaultTemplates: undefined,
        defaultBook: undefined,
        waitingReaction: 'LOADER',
      }
    ) => {
      try {
        const loaderWrapper = async <C extends () => any>(
          callback: C
        ): Promise<ReturnType<C>> => {
          try {
            setLoading(true);
            return await callback();
          } finally {
            setLoading(false);
          }
        };

        const callback = async () => {
          if (defaultTemplates && defaultBook)
            return {
              allTemplates: defaultTemplates,
              selectedBook: defaultBook,
            };
          const [allTemplates, selectedBook] = await Promise.all([
            FunwooAPI.templateApi.findAll().then((res) => res.data),
            bookId
              ? FunwooAPI.bookApi
                  .findOneByBookId(bookId)
                  .then((res) => res.data)
              : null,
          ]);

          return {
            allTemplates: defaultTemplates
              ? defaultTemplates!
              : keyBy(allTemplates, 'id'),
            selectedBook: defaultBook ? defaultBook : selectedBook,
          };
        };
        if (waitingReaction === 'TOAST') {
          return await toast.promise(callback, {
            pending: 'Updating...',
            success: 'Update Success!',
            error: 'Update Failed!',
          });
        } else if (waitingReaction === 'LOADER') {
          return await loaderWrapper(callback);
        } else {
          return await callback();
        }
      } catch (e) {
        await router.replace(pathname);
        throw e;
      }
    },
    [bookId]
  );

  useEffect(() => {
    reloadBookHandler({
      waitingReaction: 'LOADER',
      defaultBook,
      defaultTemplates: defaultTemplates?.reduce(
        (prev, curr) => ({
          ...prev,
          [curr.id]: curr,
        }),
        {}
      ),
    });
  }, []);

  useEffect(() => {
    if (!bookId) return;
    if (isNotSet(pageId) && isSet(selectedBook)) {
      router.push(`${pathname}/${bookId}/page/${selectedBook?.pages![0]?.id}`);
    }
  }, [pageId, selectedBook]);

  useEffect(() => {
    if (isNotSet(selectedBook) || isNotSet(pageId)) return;
    const selectedPage = selectedBook.pages?.find((p) => p.id === pageId);
    if (isNotSet(selectedPage)) return;
    setSelectedPage(selectedPage);
    setSelectedTemplate(allTemplates[selectedPage.templateId!]);
  }, [pageId, selectedBook]);

  const onPageSelect = useCallback(
    (pageId: string) => {
      router.push(`${pathname}/${bookId}/page/${pageId}`);
    },
    [pathname, bookId]
  );

  const saveBook = useCallback(
    (item: BookPageDataEntity, useCurrentThumbnail?: boolean) =>
      toast.promise(
        async () => {
          try {
            const newBook = deepClone(selectedBook!);
            const updatedPageIndex = newBook.pages!.findIndex(
              (page) => page.id === pageId
            );
            if (isNotSet(updatedPageIndex))
              throw new Error('The page you updating was not found.');

            const updatedDataIndex = newBook.pages![
              updatedPageIndex
            ].data.findIndex((data) => data.id === item.id);
            if (isNotSet(updatedDataIndex))
              throw new Error('The page data you updating was not found.');

            newBook.pages![updatedPageIndex].data[updatedDataIndex] = item;

            const thumb = useCurrentThumbnail
              ? newBook.pages![updatedPageIndex].templateThumb
              : skipThumbnailUpdate
              ? undefined
              : await genThumbnail(
                  selectedTemplate!.body!,
                  newBook.pages![updatedPageIndex].data
                );

            const payload: UpdateBookPageDto = {
              bookId: bookId!,
              pageId: pageId!,
              data: item,
              thumb: thumb!,
            };

            let syncedBook: Book = newBook;
            if (neverSync) {
              syncedBook = deepClone(newBook);
              if (thumb) {
                syncedBook.pages![updatedPageIndex].templateThumb = thumb;
              }
            } else {
              const result = await FunwooAPI.bookApi
                .updatePage(payload)
                .then((res) => res.data);
              if (
                selectedBook!.type === TEMPLATE_CATEGORY.LISTING_PRESENTATION ||
                selectedBook!.type === TEMPLATE_CATEGORY.ONE_PAGE_FLYER
              ) {
                syncedBook = result;
              }
            }

            await reloadBookHandler({
              defaultTemplates: allTemplates,
              defaultBook: syncedBook,
              waitingReaction: 'NONE',
            });
          } catch (e) {
            console.error('saveBook:::', e);
            throw e;
          }
        },
        {
          pending: 'Updating...',
          success: 'Update Success!',
          error: 'Update Failed!',
        }
      ),
    [
      bookId,
      pageId,
      selectedBook,
      selectedPage,
      selectedTemplate,
      allTemplates,
      reloadBookHandler,
      neverSync,
      skipThumbnailUpdate,
      selectedTemplate,
    ]
  );

  const saveWholePage = useCallback(
    (items: Array<BookPageDataEntity>) =>
      toast.promise(
        async () => {
          try {
            const newBook = deepClone(selectedBook!);
            const updatedPageIndex = newBook.pages!.findIndex(
              (page) => page.id === pageId
            );
            if (isNotSet(updatedPageIndex))
              throw new Error('The page you updating was not found.');

            newBook.pages![updatedPageIndex].data = items;

            if (skipThumbnailUpdate && neverSync) {
              await reloadBookHandler({
                defaultTemplates: allTemplates,
                defaultBook: newBook,
                waitingReaction: 'NONE',
              });
            } else {
              const thumb = await genThumbnail(
                selectedTemplate!.body!,
                newBook.pages![updatedPageIndex].data
              );

              const payload: UpdateWholeBookPageDto = {
                bookId: bookId!,
                pageId: pageId!,
                data: items,
                thumb: thumb!,
              };

              let syncedBook: Book | null = null;

              const result = await FunwooAPI.bookApi
                .updateWholePage(payload)
                .then((res) => res.data);
              if (
                selectedBook!.type === TEMPLATE_CATEGORY.LISTING_PRESENTATION ||
                selectedBook!.type === TEMPLATE_CATEGORY.ONE_PAGE_FLYER
              ) {
                syncedBook = result;
              }
              if (syncedBook) {
                await reloadBookHandler({
                  defaultTemplates: allTemplates,
                  defaultBook: syncedBook,
                  waitingReaction: 'NONE',
                });
              }
            }
          } catch (e) {
            console.error('saveBook:::', e);
            throw e;
          }
        },
        {
          pending: 'Updating...',
          success: 'Update Success!',
          error: 'Update Failed!',
        }
      ),
    [
      bookId,
      pageId,
      selectedBook,
      selectedPage,
      selectedTemplate,
      allTemplates,
      reloadBookHandler,
      neverSync,
      skipThumbnailUpdate,
      selectedTemplate,
    ]
  );

  const saveBookRef = useRef(saveBook);
  const debouncedSaveBook = useRef(
    debounce(
      async (item: BookPageDataEntity, useCurrentThumbnail?: boolean) => {
        saveBookRef.current(item, useCurrentThumbnail);
      },
      1000
    )
  );

  useEffect(() => {
    saveBookRef.current = saveBook;
  }, [saveBook]);

  return (
    <PageEditorContext.Provider
      value={{
        bookId: bookId || '',
        pageId: pageId || '',
        allTemplates,
        selectedBook,
        selectedTemplate,
        selectedPage,
        reloadBookHandler,
        onPageSelect,
        debouncedSaveBook: debouncedSaveBook.current,
        saveBook,
        saveWholePage,
      }}
    >
      {children}
    </PageEditorContext.Provider>
  );
};
