import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { FaChevronDown } from 'react-icons/fa';

export const CITY_MAP = new Map()
  .set('A', '臺北市')
  .set('B', '臺中市')
  .set('C', '基隆市')
  .set('D', '臺南市')
  .set('E', '高雄市')
  .set('F', '新北市')
  .set('G', '宜蘭縣')
  .set('H', '桃園市')
  .set('I', '嘉義市')
  .set('J', '新竹縣')
  .set('K', '苗栗縣')
  .set('M', '南投縣')
  .set('N', '彰化縣')
  .set('O', '新竹市')
  .set('P', '雲林縣')
  .set('Q', '嘉義縣')
  .set('T', '屏東縣')
  .set('U', '花蓮縣')
  .set('V', '臺東縣')
  .set('W', '金門縣')
  .set('X', '澎湖縣')
  .set('Z', '連江縣');

export const CITIES: Array<Option> = [
  {
    label: '臺北市',
    value: 'A',
  },
  {
    label: '臺中市',
    value: 'B',
  },
  {
    label: '基隆市',
    value: 'C',
  },
  {
    label: '臺南市',
    value: 'D',
  },
  {
    label: '高雄市',
    value: 'E',
  },

  {
    label: '新北市',
    value: 'F',
  },

  {
    label: '宜蘭縣',
    value: 'G',
  },
  {
    label: '桃園市',
    value: 'H',
  },
  {
    label: '嘉義市',
    value: 'I',
  },

  {
    label: '新竹縣',
    value: 'J',
  },
  {
    label: '苗栗縣',
    value: 'K',
  },
  {
    label: '南投縣',
    value: 'M',
  },
  {
    label: '彰化縣',
    value: 'N',
  },
  {
    label: '新竹市',
    value: 'O',
  },
  {
    label: '雲林縣',
    value: 'P',
  },
  {
    label: '嘉義縣',
    value: 'Q',
  },
  {
    label: '屏東縣',
    value: 'T',
  },
  {
    label: '花蓮縣',
    value: 'U',
  },
  {
    label: '臺東縣',
    value: 'V',
  },
  {
    label: '金門縣',
    value: 'W',
  },
  {
    label: '澎湖縣',
    value: 'X',
  },
  {
    label: '連江縣',
    value: 'Z',
  },
];

interface CityPickerProps {
  onChange?: (value: string) => void;
  value?: string;
}

const CityPicker: FC<CityPickerProps> = ({ onChange, value }) => {
  const onInnerChange = useCallback<
    React.ChangeEventHandler<HTMLSelectElement>
  >((e) => onChange?.(e.target.value), [onChange]);

  return (
    <label htmlFor={'city-picker'}>
      <div
        className={classNames(
          'w-[130px] h-[50px]',
          'relative flex',
          'items-center',
          'border',
          'border-gray300'
        )}
      >
        <select
          value={value}
          onChange={onInnerChange}
          className={classNames('funwoo-select', 'px-4 py-2')}
        >
          <option value={''} disabled>
            請選擇縣市
          </option>
          {CITIES?.map(({ value, label }) => {
            return (
              <option key={value} value={value}>
                {label}
              </option>
            );
          })}
        </select>
        <FaChevronDown className={'absolute right-4 pointer-events-none'} />
      </div>
    </label>
  );
};
export default CityPicker;
