import { countryUnitMap } from '@src/constant';
import { isEmptyString } from '@src/utils/formatChecker';

export const unitAreaFormatter = (country: string): string => {
  return `${countryUnitMap.get(country)?.areaUnit}`;
};
export const labelAreaFormatter = (country: string): string => {
  return `${countryUnitMap.get(country)?.areaLabel}`;
};
export const dollarFormatter = (country: string, ifEmpty?: string): string => {
  const dollar = countryUnitMap.get(country)?.dollar;

  return isEmptyString(dollar) ? ifEmpty || '' : dollar;
};
