import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import { useRouter } from 'next/router';

import axios from 'axios';

import { getTownChineseString, TowsResponse } from '@src/utils';

import {
  BuildingDescriptionModel,
  BuildingOtherRightModel,
  LandAddress_RDBID,
  LandAddressModel,
  OwnerDescriptionModel,
} from '@src/context';

import { CITY_MAP } from '@src/component/feature/CityPicker';

interface CMALandAdminReportDataContextProps {
  setCity: (city: string | null) => void;
  address: string | undefined;
  setAddress: (value: string) => void;
  openAddressDetailModal: boolean;
  setOpenAddressDetailModal: (value: boolean) => void;
  onAddressSearch: () => void;
  rdbids: LandAddress_RDBID[];
  rdbid: LandAddress_RDBID | undefined;
  setRdbid: (value: LandAddress_RDBID) => void;
  onAddressDetailPress: () => void;
  setDetailAddressIdx: (value: string) => void;
  detailAddressIdx: string;
  buildingDescription: BuildingDescriptionModel | undefined;
  buildingOtherRight: BuildingOtherRightModel | undefined;
  city: string | undefined;
  ownerDescription: OwnerDescriptionModel | undefined;
  buildingData: {
    buildType: buildType;
    totalPing: number;
    BuildingDescription: BuildingDescriptionModel;
    BuildingOtherRight: BuildingOtherRightModel;
  };
  loadingBuildingDetail: boolean;
  setLoadingBuildingDetail: (value: boolean) => void;
  setOwnerId: (ownerId: string) => void;
  ownerId: string;
}

type buildType =
  | '公寓(5樓含以下無電梯)'
  | '住宅大樓(11層含以上有電梯)'
  | '華廈(10層含以下有電梯)'
  | '透天厝'
  | '店面(店鋪)'
  | '辦公商業大樓'
  | '套房(1房1廳1衛)'
  | '工廠'
  | '廠辦'
  | '農舍'
  | '倉庫'
  | '其他'
  | undefined;
const CMALandAdminReportDataContext = createContext(
  {} as CMALandAdminReportDataContextProps
);

interface CMALandAdminReportDataContextProviderProps {}

interface BuildingData {
  buildType: buildType;
  totalPing: number;
  BuildingDescription: BuildingDescriptionModel;
  BuildingOtherRight: BuildingOtherRightModel;
  completeDate: string;
  ownerData?: OwnerDescriptionModel;
}

export const CMALandAdminContextProvider: CommonComponent<
  CMALandAdminReportDataContextProviderProps
> = ({ children }) => {
  const { push } = useRouter();
  const [city, setCity] = useState<string>();
  const [ownerId, setOwnerId] = useState('');
  const [address, setAddress] = useState<string>();
  const [openAddressDetailModal, setOpenAddressDetailModal] = useState(false);
  const [isRedirect, setIsRedirect] = useState(true);
  const [rdbids, setRdbids] = useState<LandAddress_RDBID[]>([]);
  const [rdbid, setRdbid] = useState<LandAddress_RDBID>();
  const [buildingData, setBuildingData] = useState<BuildingData>();
  const [loadingBuildingDetail, setLoadingBuildingDetail] = useState(false);
  const [buildingDescription, setBuildingDescriptionModel] =
    useState<BuildingDescriptionModel>();
  const [buildingOtherRight, setBuildingOtherRight] =
    useState<BuildingOtherRightModel>();
  const [ownerDescription, setOwnerDescription] =
    useState<OwnerDescriptionModel>();
  const [detailAddressIdx, setDetailAddressIdx] = React.useState('0');
  const updateCity = useCallback(
    (city: string) => {
      setCity(city);
    },
    [setCity]
  );
  const updateDetailAddressIdx = useCallback(
    (idx: string) => {
      setDetailAddressIdx(idx);
    },
    [setCity]
  );
  const updateAddress = useCallback(
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const val = typeof e === 'string' ? e : e?.target.value;
      setAddress(val);
    },
    [setAddress]
  );
  useEffect(() => {
    (async () => {
      if (rdbid) {
        try {
          const { data: towns } = await axios.get<TowsResponse>(
            'https://api.nlsc.gov.tw/other/ListTown1/' + city
          );
          const address =
            CITY_MAP.get(city) +
            getTownChineseString(towns, rdbid.UNIT, rdbid.DD48, city!) +
            rdbids[parseInt(detailAddressIdx)].DD09;
          setAddress(address);
          await getBuildDetail(address);
        } catch (error) {}
      }
    })();
  }, [rdbid]);
  const getBuildDetail = async (address: string) => {
    setBuildingData(undefined);
    setBuildingOtherRight(undefined);
    setBuildingDescriptionModel(undefined);
    const { data } = await axios.post<BuildingData>(
      '/cma/api/building_detail',
      {
        unit: rdbid?.UNIT,
        sec: rdbid?.DD48,
        no: rdbid?.DD49,
        city: city,
        ownerId,
      }
    );
    if (ownerId) {
      await axios.post<{
        buildType: buildType;
        BuildingDescription: BuildingDescriptionModel;
        BuildingOtherRight: BuildingOtherRightModel;
      }>('/cma/api/id_get_land_owner_ship', {
        unit: rdbid?.UNIT,
        sec: rdbid?.DD48,
        no: rdbid?.DD49,
        ids: ownerId,
      });
    }

    setBuildingData(data);
    setBuildingOtherRight(data.BuildingOtherRight);
    setBuildingDescriptionModel(data.BuildingDescription);
    setOwnerDescription(data?.ownerData);

    const { data: record, status } = await axios.post(
      '/cma/api/create_record',
      {
        unit: rdbid?.UNIT,
        sec: rdbid?.DD48,
        no: rdbid?.DD49,
        address: address,
        city: city,
      }
    );

    toast.info('資料獲取成功');
    if (status === 200 && isRedirect) {
      await push({
        pathname: '/cma-report/cma-create',
        query: {
          id: record.id,
        },
      });
    }
    return record;
  };
  const onAddressSearch = useCallback(
    async (ownerId?: string) => {
      try {
        setLoadingBuildingDetail(true);
        if (city && address) {
          const { data } = await axios.post<LandAddressModel>(
            '/cma/api/address_get_rdbid',
            {
              city,
              address,
              ownerId,
            }
          );
          if (data.RDBID?.length >= 0) {
            setOpenAddressDetailModal(true);
            setRdbids(data.RDBID);
            toast.info('搜尋到多個結果，請選擇。');
            return [...data.RDBID];
          } else if (data.RDBID.length === 1) {
            setRdbid(data.RDBID[0]);
            return data.RDBID;
          }
          return [];
        }
      } catch (error: any) {
        if (typeof error === 'object') {
          toast.error((error as Record<string, any>)?.response?.data as string);
        }
        return [];
      } finally {
        setLoadingBuildingDetail(false);
      }
    },
    [address, city]
  );
  const onAddressDetailPress = useCallback(() => {
    setRdbid(rdbids[parseInt(detailAddressIdx)]);
    setOpenAddressDetailModal(false);
  }, [rdbids, detailAddressIdx]);
  return (
    <CMALandAdminReportDataContext.Provider
      value={{
        ownerDescription,
        //@ts-ignore
        buildingData,
        //@ts-ignore
        setCity: updateCity,
        setIsRedirect: setIsRedirect,
        address,
        setAddress: updateAddress,
        openAddressDetailModal,
        setOpenAddressDetailModal,
        onAddressSearch,
        rdbid: rdbid,
        detailAddressIdx,
        rdbids: rdbids,
        setRdbid: setRdbid,
        setDetailAddressIdx: updateDetailAddressIdx,
        onAddressDetailPress,
        buildingDescription,
        buildingOtherRight,
        city,
        loadingBuildingDetail,
        setLoadingBuildingDetail,
        setOwnerId,
        ownerId,
      }}
    >
      {children}
    </CMALandAdminReportDataContext.Provider>
  );
};
export const useCMALandReportDataContextProvider = () => {
  return useContext(CMALandAdminReportDataContext);
};
