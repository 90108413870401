import { FunwooAPI } from '@src/swagger';

export interface UploadAdImageResponse {
  errorCode?: number;
  errorMsg?: string;
  errorTitle?: string;
  data?: string;
}

const uploadImageByUrl = async (
  url: string
): Promise<UploadAdImageResponse> => {
  try {
    const { data } = await FunwooAPI.facebookApi.uploadImage({
      image_url: url,
    });
    return { data: data.images.bytes?.hash ?? '' };
  } catch (error: any) {
    const res = error.response as {
      code: number;
      error_user_title: string;
      error_user_msg: string;
    };
    return {
      errorCode: res.code,
      errorTitle: res.error_user_title,
      errorMsg: res.error_user_msg,
    };
  }
};

const uploadImage = async (file: File): Promise<UploadAdImageResponse> => {
  const promise = new Promise<string>((resolve, reject) => {
    let image: string | ArrayBuffer = '';
    const reader = new FileReader();
    reader.onload = async (e) => {
      image = e.target!.result!;
      if (typeof image === 'string') {
        image = image.split(',')[1];
      }
      try {
        const { data } = await FunwooAPI.facebookApi.uploadImage({
          image: image as string,
        });
        resolve(data.images.bytes?.hash ?? '');
      } catch (error) {
        reject(error);
      }
    };
    reader.readAsDataURL(file);
  });
  try {
    const id = await promise;
    return { data: id };
  } catch (error: any) {
    const { data } = error.response;
    const res = data.response.error;
    return {
      errorCode: res.code,
      errorTitle: res.error_user_title,
      errorMsg: res.error_user_msg,
    };
  }
};

export { uploadImage, uploadImageByUrl };
