import { LANDNO_SUBSECTION } from '@src/constant';

interface SectionsProps {
  Section: string;
  SubSection: string;
  SectionCode: string;
  Comment: string;
  TownCode: string;
}

type SectionsType = SectionsProps[];
export type TowsResponse = {
  towncode: string;
  towncode01: string;
  townname: string;
}[];

export const getLandnoSubsectionItem = (
  OfficeCode: string,
  SectionCode: string
) => {
  const Sections = LANDNO_SUBSECTION.filter((item) => {
    return item.OfficeCode === OfficeCode;
  });
  let temp: SectionsType = [];
  Sections.map((i) => {
    const tempSections = i.Sections.map((res) => {
      return { ...res, TownCode: i.TownCode };
    });
    temp = temp.concat(tempSections);
  });
  const section = temp.filter((item) => item.SectionCode === SectionCode)[0];
  return { section };
};
export const getLandnoSubsectionString = (UNIT: string, SEC: string) => {
  const {
    section: { Section, SubSection },
  } = getLandnoSubsectionItem(UNIT, SEC);
  return Section + '段' + SubSection + '小段' + '  ';
};

export const getTownChineseString = (
  data: TowsResponse,
  OfficeCode: string,
  SectionCode: string,
  city: string
) => {
  const {
    section: { TownCode },
  } = getLandnoSubsectionItem(OfficeCode, SectionCode);
  return (
    data.filter((item) => item.towncode01 === city + TownCode)?.[0]?.townname ??
    ''
  );
};
