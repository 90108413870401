// Diff objects recursively
// https://stackoverflow.com/questions/27936772/how-to-deep-compare-two-objects-with-lodash
import _ from "lodash";

export const deepObjectDiff = <Obj>(
  obj1: Partial<Obj>,
  obj2: Partial<Obj>
): Partial<Obj> => {
  const changes = <Obj>(
    object: Partial<Obj>,
    base: Partial<Obj>
  ): Partial<Obj> => {
    return _.transform<Partial<Obj>, Partial<Obj>>(
      object,
      (result, value, key) => {
        const baseValue = base[key as keyof Obj];
        const keyInObject = key in object;
        if (!_.isEqual(value, baseValue) && keyInObject) {
          result[key] =
            // If value id array, then replace with new value
            _.isArray(value) && _.isArray(baseValue)
              ? value
              : // If value is object, then recursively call changes
              _.isObject(value) && _.isObject(baseValue)
              ? changes(value, baseValue!)
              : (value as any);
        }
      }
    );
  };
  return changes(obj1, obj2);
};
