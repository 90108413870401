import React, { useCallback, useContext, useMemo, useState } from 'react';

import { LockIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Image, Spacer, Text } from '@chakra-ui/react';
import { useRouter } from 'next/router';

import { pathname } from '@src/constant';

import { isAdmin } from '@src/utils';

import useT from '@src/hooks/useT';

import { BackyardGlobalContext } from '@src/context';

import ActiveLink from '@src/component/common/ActiveLink';
import {
  AdsIcon,
  ChevronLeft,
  ChevronRight,
  FlyerIcon,
  Logo,
  PresentationIcon,
  SocialMediaIcon,
} from '@src/component/common/icons';
import { useAsync } from 'react-use';
import { FunwooAPI } from '@src/swagger';

export enum NAV {
  'ADS' = 'ADS',
  'SALES_DASHBOARD' = 'SALES_DASHBOARD',
  'LISTING_PRESENTATION' = 'LISTING_PRESENTATION',
  'PUBLICATION_MANAGER' = 'PUBLICATION_MANAGER',
  'SOCIAL_MEDIA' = 'SOCIAL_MEDIA',
  'ONE_PAGE_FLYER' = 'ONE_PAGE_FLYER',
  'TEMPLATE_MANAGER' = 'TEMPLATE_MANAGER',
  'ADMIN' = 'ADMIN',
  'BLOG' = 'BLOG',
  'CMA' = 'CMA',
  'LISTINGS' = 'LISTINGS',
  'BUILDINGS' = 'BUILDINGS',
  'BUILDING_SORTING' = 'BUILDING_SORTING',
  'LISTING_SORTING' = 'LISTING_SORTING',
  'REFERRAL_MANAGER' = 'REFERRAL_MANAGER',
  'USER_ROLE' = 'USER_ROLE',
  'AGENTS' = 'AGENTS',
  'CONFIG_SETTING' = 'CONFIG_SETTING',
}

type Item = {
  key: NAV;
  label?: string;
  href: string;
  icon: React.FC<{ selected: boolean; color: string; boxSize: string }>;
  disabled?: boolean;
  foreign?: boolean;
};

const ListItem: React.FC<{
  label: string;
  data: {
    icon: React.FC<{ selected: boolean; color: string; boxSize: string }>;
  };
  // selected: boolean;
  // select: () => void;
  isOpen: boolean;
  href: string;
  foreign?: boolean;
}> = ({ label, data: { icon: Icon }, href, isOpen, foreign }) => {
  return (
    <ActiveLink href={`${href}`} foreign={foreign}>
      {({ isActive }) => (
        <Flex
          role='a'
          _hover={{
            cursor: 'pointer',
          }}
          w={isOpen ? '100%' : '64px'}
          mt='0.25rem'
        >
          <Box
            className='list-item-light-bar'
            position='absolute'
            w='4px'
            h='44px'
            borderBottomRightRadius='4px'
            borderTopRightRadius='4px'
            display={isActive ? 'block' : 'none'}
            color='white'
            bg='white'
          />
          <Flex align='center' px={isOpen ? '2rem' : '18px'} py='9px'>
            <Icon selected={isActive} color='gray.400' boxSize='1.4rem' />
            <Text
              display={isOpen ? 'flex' : 'none'}
              color='white'
              opacity={isActive ? 1 : 0.65}
              fontWeight='800'
              pl='10px'
              _hover={{
                opacity: 1,
              }}
            >
              {`${label}`}
            </Text>
          </Flex>
        </Flex>
      )}
    </ActiveLink>
  );
};

const DrawerTitleBar: React.FC<{ isOpen: boolean }> = ({ isOpen }) => {
  const router = useRouter();
  const handleClick = useCallback(() => {
    router.push('/');
  }, [router]);

  return (
    <Flex
      bg='#0B0B0B'
      w={isOpen ? '100%' : '64px'}
      py='20px'
      px='20px'
      align='center'
      _hover={{
        cursor: 'pointer',
      }}
      onClick={handleClick}
    >
      {isOpen ? (
        <Image w='140px' ml='6px' src='/assets/images/logo.png' />
      ) : (
        <Logo />
      )}
    </Flex>
  );
};

const DrawerList: React.FC = () => {
  const t = useT('backyard.nav');
  const { isMenuOpen, user } = useContext(BackyardGlobalContext);

  const NAV_ITEMS: Array<Item> = useMemo(
    () => [
      {
        key: NAV.LISTING_PRESENTATION,
        icon: PresentationIcon,
        href: pathname.listingPresentation,
      },
      {
        key: NAV.SOCIAL_MEDIA,
        icon: SocialMediaIcon,
        href: pathname.socialMediaAssets,
      },
      {
        key: NAV.ONE_PAGE_FLYER,
        icon: FlyerIcon,
        href: pathname.onePageFlyer,
      },
      // {
      //   key: NAV.ADS,
      //   icon: AdsIcon,
      //   href: pathname.adsCenter,
      //   disabled: user?.email !== 'wei@funwoo.com.tw',
      // },
      {
        key: NAV.SALES_DASHBOARD,
        icon: AdsIcon,
        href: pathname.salesDashboard,
      },
      {
        key: NAV.CMA,
        icon: AdsIcon,
        href: pathname.CMA,
      },
      {
        key: NAV.REFERRAL_MANAGER,
        icon: AdsIcon,
        href: pathname.referralManager,
      },
    ],
    [user?.email]
  );

  const ADMIN_ITEMS: Array<Item> = useMemo(
    () => [
      {
        key: NAV.USER_ROLE,
        icon: LockIcon,
        href: pathname.userRole,
      },
      {
        key: NAV.PUBLICATION_MANAGER,
        icon: LockIcon,
        href: pathname.publicationManager,
      },
      {
        key: NAV.TEMPLATE_MANAGER,
        icon: LockIcon,
        href: pathname.templateManager,
      },
      {
        key: NAV.ADMIN,
        icon: LockIcon,
        href: pathname.admin,
      },
      {
        key: NAV.BLOG,
        label: 'Blog (Media)',
        icon: LockIcon,
        href: process.env.NEXT_BLOG_BACKYARD_URL,
        foreign: true,
      },
      {
        key: NAV.LISTINGS,
        icon: LockIcon,
        href: pathname.listing,
      },
      {
        key: NAV.LISTING_SORTING,
        icon: LockIcon,
        href: pathname.listingSorting,
      },
      {
        key: NAV.BUILDINGS,
        icon: LockIcon,
        href: pathname.buildings,
      },
      {
        key: NAV.BUILDING_SORTING,
        icon: LockIcon,
        href: pathname.buildingSorting,
      },
      {
        key: NAV.AGENTS,
        icon: LockIcon,
        href: pathname.agents,
      },
      {
        key: NAV.CONFIG_SETTING,
        icon: LockIcon,
        href: pathname.configSetting,
      },
    ],
    []
  );

  return (
    <Flex w={isMenuOpen ? '100%' : '64px'} justify='center' direction='column'>
      {NAV_ITEMS.map((item) => (
        <ListItem
          key={item.key}
          label={item.label ?? t(item.key)}
          data={item}
          href={item.href}
          isOpen={isMenuOpen}
          foreign={item.foreign}
        />
      ))}
      {isAdmin(user?.email) &&
        ADMIN_ITEMS.map((item) =>
          item.disabled ? null : (
            <ListItem
              key={item.key}
              label={item.label ?? t(item.key)}
              data={item}
              href={item.href}
              isOpen={isMenuOpen}
              foreign={item.foreign}
            />
          )
        )}
    </Flex>
  );
};

const Drawer: CommonComponent = () => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);

  const { isMenuOpen, setIsMenuOpen } = useContext(BackyardGlobalContext);

  const { value: serverEnvironment } = useAsync(async () => {
    const { data } = await FunwooAPI.server.getServerEnvironment();
    return data;
  }, []);

  const setIsOpen = useCallback(() => {
    setIsMenuOpen((prev) => !prev);
  }, []);

  return (
    <Flex
      id={'drawer'}
      position={'relative'}
      className={'overflow-scroll'}
      h={'100vh'}
      maxH={'100vh'}
      flexShrink={0}
      minW={isMenuOpen ? `${ref?.scrollWidth || 0}px` : '64px'}
    >
      <Flex
        key={`${isMenuOpen}`}
        ref={setRef}
        id='leftMenu'
        position={'fixed'}
        bg='#0B0B0B'
        top={0}
        overflow={'scroll'}
        h='100vh'
        direction='column'
        align='center'
      >
        <DrawerTitleBar isOpen={isMenuOpen} />
        <DrawerList />
        <Spacer />
        <Flex
          w='100%'
          px='20px'
          onClick={setIsOpen}
          _hover={{
            cursor: 'pointer',
          }}
        >
          <IconButton
            aria-label={'drawer-trigger'}
            icon={isMenuOpen ? <ChevronLeft /> : <ChevronRight />}
          />
        </Flex>
        <Text pb='40px'>
          <Box as={'span'} hidden={!isMenuOpen}>
            current server mode:
          </Box>
          {serverEnvironment}
        </Text>
      </Flex>
    </Flex>
  );
};

export default Drawer;
